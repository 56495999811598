import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directorsunilkumar() {
   useEffect(() => {
      window.scrollTo(0, 0)

    }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Shri Saurabh Agarwal
</h2>
                          <p>Managing Director</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/saurbh.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Shri Saurabh Agarwal is a dynamic entrepreneur and an expert in complex plants
operations. He has been associated with Kamdhenu Ventures Limited since 2019. He is B.E. in Mechanical Engineering from R.V. College of Engineering, Bangaluru.</p>

<p>Shri Saurabh Agarwal has been instrumental in the diversification of the company&#39;s
portfolio. In 2007, he established a state-of-the-art paint manufacturing unit in
Chopanki, Rajasthan. Today the company takes pride in being one of the largest
manufacturers of decorative paints in India. Ambitious and energetic, Shri Saurabh
Agarwal is committed to taking the Kamdhenu brand to greater heights. </p>
                      
                <p>Under his watch, the paints business has grown from strength to strength.
A natural trouble-shooter, Shri Saurabh Agarwal closely monitors the production
process to ensure smooth operations and that quality standards are being
maintained. In addition to his technical expertise, Shri Agarwal has shown
remarkable entrepreneurial skills and has been consistently delivering impressive
results to the company. He adds a dash of dynamism to the company&#39;s top
management while ensuring that the company functions smoothly and in line with
strict quality parameters.</p>
                


                  </div>
               
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directorsunilkumar;
