import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import MetaTags from 'react-meta-tags';


function Distemperange () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>
    <MetaTags>
      <title>Acrylic Distemper Paints - Kamdhenu Paints</title>
      <meta name="description" content="Select from the wide range of Kamosilky Acrylic Washable Distemper, Kyson Acrylic Distemper, Bunty Acrylic Distemper offered by Kamdhenu paints" />
      <meta name="keywords" content="acrylic wall paint, distemper paint" />
  </MetaTags>

    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Distemper Range </h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/distemper-range/1.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamosilky
</h5>  
                        <small>Acrylic Washable Distemper

</small>
                            <p>Kamosilky distemper range is made to give unmatched durability and a smooth finish. Its delightful and attractive matt finish lasts long keeping your home graceful for ages. Kamosilky is available in a unique range of 500 attractive shades giving you the liberty to choose your walls according to your personality


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/distemper-range/2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kyson
</h5>
                            <small>Acrylic Distemper

</small>
                            <p>Kyson Acrylic Distemper specially formulated acrylic copolymer emulsion based distemper for elegant, smooth, durable, matt for interiors. It gives wall a delightful, attractive matt finish that lasts for ages.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/distemper-range/3.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Bunty
</h5>
                            <small>Acrylic Distemper</small>
                            <p>If you are looking for an economical yet durable wall paint your search ends at Bunty Acrylic Distemper. It is specially formulated water based interior wall paint which retains the brightness of the all for a longer duration. It brings smooth and matt finish to your home and can be tinted with Kamo universal Stainer to obtain customized shades.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                




               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Distemperange ;
