import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import MetaTags from 'react-meta-tags';

function Enamelrange () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <MetaTags>
    <title>Premium Enamel Paints | Enamel Paint -  Kamdhenu Paints</title>
    <meta name="description" content="Pick from the huge range of premium enamel paints designed to provide a hard wearing finish for your doors and windows. Contact now!" />
    <meta name="keywords" content="premium emulsion, enamel paint" />
</MetaTags>

  <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Enamel Range </h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/premium-enamel-range/1.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamolite
</h5>  
                        <small>High Gloss Water Base Enamel
</small>
                            <p>Kamolite Water Base Enamel is a gloss finish eco friendly paint based on acrylic resin, Inorganic and Organic lead freepigments. It is applicable on Exterior and Interior wall, wooden as well as metal surfaces, Easy to apply by thinning down with water.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/premium-enamel-range/2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamolite
</h5>
                            <small>Premium Enamel Paint
</small>
                            <p>Kamolite premium satin enamel is a solvent based paint formulated specially for interior and exterior surfaces and can be used on wooden surface like doors, windows, cabinets, on metal surface like grills, gates and on suitably prepared masonry wall surfaces.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/premium-enamel-range/3.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Babli
</h5>
                            <small>Synthetic Enamel Paint
</small>
                            <p>Babli Enamel is an economical solvent - based paint, which gives long lasting glossy finish to the walls. It also imparts a glossy finish to the painted Surface which keeps it looking as a good as new. Babli synthetic enamel paint is resistant to stain and water...


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                




               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Enamelrange ;
