import React, { Component, useState, useEffect } from "react";
import './index.css';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import GetAppIcon from '@mui/icons-material/GetApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import ImageIcon from '@mui/icons-material/Image';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';




function DisclosuresunderRegulation30() {

  const [productdata, setProductData] = useState([]);

  const productdata2 = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": { "Catagory": "Disclosure under Regulation 30 of LODR", }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 1 }
  function ProductData1() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {

        method: "POST",
        headers: {
          "Authorization-Token":
            "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);


      });
    });
  }

  useEffect(() => {
    ProductData1();
  }, []);
  const playAudioInNewTab = (audioUrl) => {
    const newTab = window.open('', '_blank');
    newTab.document.write(`
          <html>
          <head>
            <title>Audio Player</title>
              <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                }
              </style>
            </head>
            <body>
              <audio controls autoplay>
                <source src="${audioUrl}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </body>
          </html>
          
        `);
  };
  return (
    <>    <Header />
      <div className="contentblock">
        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-md-12">
              <div className="section__title-wrapper">
                <h2 className="section__title font-36">Disclosure under Regulation 30 of LODR</h2>
              </div>
              <div className="tableblock" style={{ border: '1px solid #d3d3d3' }}>
                <table cellPadding={0} cellSpacing={0} width={100}>
                  {/* {productdata?.response?.records?.slice().sort((a, b) => b[4].value.localeCompare(a[4].value)).map((productitem11, i) => { */}
                  {productdata?.response?.records?.slice().sort(function (a, b) { return new Date(b[4].value) - new Date(a[4].value) }).map((productitem11, i) => {

                    var splitSentence = productitem11[7].value.split(" ");
                    return (
                      <tr key={productitem11.productdata}>
                        <td>
                          {/* {productitem11[7].value} */}
                          {splitSentence.map((b, i) => {
                            let flag = b.includes("https");
                            if (flag === true) {
                              return (<><span><a href={b} style={{ color: "blur" }} target="_blank"> {b}</a></span></>);
                            }
                            else { return (<> <span> {b}</span> </>); }
                          })}
                        </td>
                        <td className="download">

                          {productitem11.map((productitem12, i) => {

                            if (productitem12.name === "Pdf" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  <a href={productitem12.value} download><GetAppIcon /></a>

                                </div>

                              )
                            }



                            if (productitem12.name === "LargeSizeFileUrl" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  <a href={productitem12.value} target="_blank" download><GetAppIcon /></a>

                                </div>

                              )
                            }

                            if (productitem12.name === "Image" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  <a href={productitem12.value} download><ImageIcon /></a>

                                </div>

                              )
                            }



                            if (productitem12.name === "Audio" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  {/* <a href={productitem12.value} download><VolumeDownIcon/></a> */}
                                  <button onClick={() => playAudioInNewTab(productitem12.value)} style={{ color: '#ff0000', textAlign: 'right', float: 'right', border: '0px', padding: '0px', backgroundColor: '#ffffff00' }}><VolumeDownIcon /></button>
                                </div>
                              )
                            }
                            if (productitem12.name === "AudioUrl" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  <a href={productitem12.value}  target="_blank"><VolumeDownIcon /></a>
                                </div>
                              )
                            }

                            if (productitem12.name === "VideoUrl" && productitem12.value !== "") {
                              return (
                                <div key={productitem12.productdata}>
                                  <a href={productitem12.value} download><OndemandVideoIcon /></a>

                                </div>

                              )
                            }

                          })}


                        </td>

                      </tr>
                    );

                  })}


                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div><Footer /></div>
    </>

  );
}

export default DisclosuresunderRegulation30;
