import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Thankyou() {


  return (
    <>    <Header/>
    <div className="contentblock" style={{background:'#f8f8f8'}}>
     
<section className="section appoinment">
	<div className="" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
		<div className="row align-items-center text-center">
      
      <div style={{margin: ' 0 auto', border: ' 1px solid #d3d3d3', padding: '20px 20px', background:'#fff'}}>
   
            <div className="pop-up-content-wrap" style={{textAlign: 'center', fontSize: '17px', fontWeight: '600'}}>
            <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Slide" style={{width: '145px'}} />
                <img src={process.env.PUBLIC_URL + '/images/contact.svg'} alt="Slide" style={{width: '300px', display:'block', margin: '0 auto'}} />
                <p style={{fontWeight:'600', marginBottom:'0px', color:'#000'}}>Thanks for sharing details our team will connect you soon</p>
               <p style={{textAlign:'center', width:'100%', fontWeight:'600', marginBottom:'0px', color:'#000'}}>Back to <NavLink to="/" style={{color: '#1a0dab'}}>Home</NavLink></p>
            </div>
		</div>
	</div>
	</div>
</section>


    </div>

<div><Footer /></div>
    </>

  );
}

export default Thankyou;
