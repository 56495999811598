import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directorsachinagarwal() {
  useEffect(() => {
    window.scrollTo(0, 0)

  }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Shri Sachin Agarwal
</h2>
                          <p>Non-Executive Director</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/sachin.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Shri Sachin Agarwal is a BE in Industrial Engineering from R.V. College of Engineering, Bangalore. He
got his MBA in International Management from the UK. He joined Kamdhenu Ventures Limited in 2019 and
has been a vital part of the company&#39;s management team. He is responsible for the manufacturing
units&#39; technological advancement, bringing products&#39; international quality standards to the
Kamdhenu Group.</p>
<p>He played an essential role in teaming up with Centre de Recherches Metallurgiques (CRM) Belgium
to help implement TEMPCORE technology in the Bhiwadi manufacturing unit.</p>
<p>Shri Agarwal has been closely monitoring Kamdhenu&#39;s Research &amp; Development (R&amp;D) facility to
ensure that the company&#39;s products are economically viable. His efforts, extensive research and
technical know-how from the United Kingdom have enabled Kamdhenu to launch its premium
product of reinforcement rebars, KAMDHENU SS 10000 TMT. This product has unique features that
make it unmatchably solid and suitable for highly seismic and high-rise construction. </p>
                    


                  </div>
                  <div className="col-xl-12 col-lg-12">
                
                <p>Shri Agarwal always puts a premium on quality and sees to jobs are done with speed and precision.
He monitors the manufacturing process of all Kamdhenu products to guarantee that only the best-
quality items are sent out to customers. To achieve this, all units -including those under franchise-
are equipped with state-of-the-art quality monitoring devices and a team dedicated to meeting the
company&#39;s high standards.</p>
                
                
                </div>
               
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directorsachinagarwal;
