import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { browserHistory } from 'react-router';
import { BrowserRouter, Route, Link, Routes, Switch, HashRouter, Navigate} from 'react-router-dom';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import Policies from './Policies';
import About from './About';
import Dealer from './Dealer';
import Distributor from './Distributor';
import Contractor from './Contractor';
import Vendor from './Vendor';
import Contact from './Contact';
import Thankyou from './Thankyou';
import DualPaintSeries from './products/DualPaintSeries';
import FinancialResults from './FinancialResults';
import AnnualReport from './AnnualReport';
import AnnualReturn from './AnnualReturn';
import ShareholdingPattern from './ShareholdingPattern';
import InvestorInformation from './InvestorInformation';
import NoticetoShareholders from './NoticetoShareholders';
import Presentation from './Presentation';
import CodeofConduct from './CodeofConduct';
import StockExchangeDisclosures from './StockExchangeDisclosures';
import SubsidiaryFinancials from './SubsidiaryFinancials';
import SchemeofArrangement from './SchemeofArrangement';
import InvestorHelpDesk from './InvestorHelpDesk';
import GeneralMeeting from './GeneralMeeting';
import DisclosuresunderRegulation46 from './DisclosuresunderRegulation46';
import DisclosuresunderRegulation30 from './DisclosuresunderRegulation30';
import Directorsunilkumar from './Directorsunilkumar';
import Directorsaurabhaggarwal from './Directorsaurabhaggarwal';
import Directorsachinagarwal from './Directorsachinagarwal';
import Directormadhusudanagarwal from './Directormadhusudanagarwal';
import Directorrameshchandsurana from './Directorrameshchandsurana';
import Directornishaljain from './Directornishaljain';
import Pickyourcolors from './Pickyourcolors';
import Designerange from './products/Designerange';
import Exteriorange from './products/Exteriorange';
import Interiorange from './products/Interiorange';
import Distemperange from './products/Distemperange';
import Enamelrange from './products/Enamelrange';
import Woodfinish from './products/Woodfinish';
import Waterproofing from './products/Waterproofing';
import Privacy from './Privacy';
import Terms from './Terms';
import Listingofshares from './Listingofshares';
import KamoHiSheen from './products/Interior/KamoHiSheen';
import Faq from './Faq';
import Blog from './Blog';
import Velvety from './products/Interior/Velvety';
import KamoShineAdvance from './products/Interior/KamoShineAdvance';
import KamosilkyAdvance from './products/Interior/KamosilkyAdvance';
import SheenShineStainFree from './products/Interior/SheenShineStainFree';
import Kamorich from './products/Interior/Kamorich';
import Kamosilky from './products/Interior/Kamosilky';
import BlogDetails from './BlogDetails';
import KamoShield from './products/Exterior/KamoShield';
import WeatherClassic from './products/Exterior/WeatherClassic';
import KamoShieldAdvances from './products/Exterior/KamoShieldAdvances';
import WeatherSupremeAdvanceShyne from './products/Exterior/WeatherSupremeAdvanceShyne';
import WeatherClassicAdvance from './products/Exterior/WeatherClassicAdvance';
import WeatherClassicMax from './products/Exterior/WeatherClassicMax';
import WeatherSupreme from './products/Exterior/WeatherSupreme';
import KamodualGoldEmulsion from './products/Kamodual/KamodualGoldEmulsion';
import KamodualLuxuryEmulsion from './products/Kamodual/KamodualLuxuryEmulsion';
import KamodualPremiumEmulsion from './products/Kamodual/KamodualPremiumEmulsion';
import NotFound from './NotFound';
import News from './News';
import Product from './products/Product';
import ProductDetails from './products/ProductDetails';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={''} forceRefresh={true}>
    <Routes>
      <Route path='/' exact element={<Home/> } />
      <Route path='/news' exact element={<News/> } />
      <Route path='/investor-zone' exact element={<Policies/> } />
      <Route path='/about' exact element={<About/> } />
      <Route path='/dealer' exact element={<Dealer/> } />
      <Route path='/distributor' exact element={<Distributor/> } />
      <Route path='/contractor' exact element={<Contractor/> } />
      <Route path='/vendor' exact element={<Vendor/> } />
      <Route path='/contact' exact element={<Contact/> } />
      <Route path='/thankyou' exact element={<Thankyou/> } />
      <Route path='/financial-results' exact element={<FinancialResults/> } />
      <Route path='/annual-results' exact element={<AnnualReport/> } />
      <Route path='/annual-return' exact element={<AnnualReturn/> } />
      <Route path='/shareholding-pattern' exact element={<ShareholdingPattern/> } />
      <Route path='/investor-information' exact element={<InvestorInformation/> } />
      <Route path='/notice-to-shareholders' exact element={<NoticetoShareholders/> } />
      <Route path='/presentation' exact element={<Presentation/> } />
      <Route path='/code-of-conduct' exact element={<CodeofConduct/> } />
      <Route path='/stock-exchange-disclosures' exact element={<StockExchangeDisclosures/> } />
      <Route path='/subsidiary-financials' exact element={<SubsidiaryFinancials/> } />
      <Route path='/scheme-of-arrangement' exact element={<SchemeofArrangement/> } />
      <Route path='/investor-help-desk' exact element={<InvestorHelpDesk/> } />
      <Route path='/general-meeting' exact element={<GeneralMeeting/> } />
      <Route path='/disclosures-under-regulation46' exact element={<DisclosuresunderRegulation46/> } />
      <Route path='/disclosures-under-regulation30' exact element={<DisclosuresunderRegulation30/> } />
      <Route path='/sunil-kumar' exact element={<Directorsunilkumar/> } />
      <Route path='/saurabh-aggarwal' exact element={<Directorsaurabhaggarwal/> } />
      <Route path='/sachin-agarwal' exact element={<Directorsachinagarwal/> } />
      <Route path='/madhusudan-agarwal' exact element={<Directormadhusudanagarwal/> } />
      <Route path='/ramesh-chand-surana' exact element={<Directorrameshchandsurana/> } />
      <Route path='/nishal-jain' exact element={<Directornishaljain/> } />
      <Route path='/pickyourcolors.html' exact element={<Pickyourcolors/> } /> 
      <Route path='/pickyourcolors' exact element={<Pickyourcolors/> } />  
      <Route path='/dual-paints-series' exact element={<DualPaintSeries/> } />
      <Route path='/Product/:id' exact element={<Product/> } />
      <Route path='Product/Details/:id' exact element={<ProductDetails/> } />
      
      <Route path='/designer-range' exact element={<Designerange/> } />     
      <Route path='/exterior-range' exact element={<Exteriorange /> } />    
      <Route path='/interior-range' exact element={<Interiorange /> } />     
      <Route path='/distemper-range' exact element={<Distemperange /> } />     
      <Route path='/premium-enamel-range' exact element={<Enamelrange /> } />    
      <Route path='/wood-coating-products' exact element={<Woodfinish /> } />     
      <Route path='/water-proofing-solutions' exact element={<Waterproofing /> } />  
      <Route path='/privacy-policy' exact element={<Privacy /> } />   
      <Route path='/terms-and-conditions' exact element={<Terms /> } />   
      <Route path='/listing-of-shares' exact element={<Listingofshares /> } />   
      <Route path='/faq' exact element={<Faq/> } /> 
      <Route path='/blog' exact element={<Blog/> } />
      <Route path='/Kamo-Hi-Sheen' exact element={<KamoHiSheen/> } />
      <Route path='/velvety' exact element={<Velvety/> } />
      <Route path='/kamoshine-advance' exact element={<KamoShineAdvance/> } />
      <Route path='/kamosilky-advance' exact element={<KamosilkyAdvance/> } />
      <Route path='/sheen-n-shine-stain-free' exact element={<SheenShineStainFree/> } />
      <Route path='/kamorich' exact element={<Kamorich/> } />
      <Route path='/kamosilky' exact element={<Kamosilky/> } />
      <Route path='/blog-details/:id' exact element={<BlogDetails/> } />
      <Route path='/kamoshield' exact element={<KamoShield/> } />
      <Route path='/weather-classic' exact element={<WeatherClassic/> } />
      <Route path='/kamo-shield-advance' exact element={<KamoShieldAdvances/> } />
      <Route path='/kamo-supreme-advance-shyne' exact element={<WeatherSupremeAdvanceShyne/> } />
      <Route path='/weather-classic-advance' exact element={<WeatherClassicAdvance/> } />
      <Route path='/weather-classic-max' exact element={<WeatherClassicMax/> } />
      <Route path='/weather-supreme' exact element={<WeatherSupreme/> } />
      <Route path='/kamodual-gold-emulsion' exact element={<KamodualGoldEmulsion/> } />
      <Route path='/kamodual-luxury-emulsion' exact element={<KamodualLuxuryEmulsion/> } />
      <Route path='/kamodual-premium-emulsion' exact element={<KamodualPremiumEmulsion/> } />
     
      <Route path='/not-found' exact element={<NotFound/> } />
      <Route path="/*" element={<Navigate to="/not-found" />} />      
      
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
