import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import $ from 'jquery'; 


function Faq() {

  $(function () {

    /* faq toggle */
    $(function () {
        $(".faq__question").on('click', function () {
            let box = $(this).closest(".faqq");
            if (box.hasClass('faq_active')) {
                box.find('.faq__answer').slideUp(300);
                box.removeClass('faq_active');
            } else {
                box.find('.faq__answer').slideDown(300);
                box.addClass('faq_active');
            }
        });
    });
});

  const [productdata, setProductData] = useState([]);

  

  const productdata2 = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": { "Catagory": "Stock Exchange Disclosures", }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 2 }

  function ProductData1() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {

        method: "POST",
        headers: {
          "Authorization-Token":
            "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);

        console.log(resp, 'data')

      });
    });
  }
  useEffect(() => {
    ProductData1();

  }, []);




  return (
    <>    <Header/>
    <div className="contentblock faq" style={{background:'#f8f8f8'}}>
    <div className="container">
               <div className="row">
                   
                     <div className="col-lg-12 col-md-12">
 

                     <div className="section__title-wrapper">
                <h2 className="section__title font-36">FAQ</h2>
              </div>

    <div class="faq-list">
    { productdata?.response?.records?.map((productitem122, i) => {
                    console.log(productitem122.value, 'name')

                    return (
	<div class="faqq">
		<div class="faq__question">

			<div class="faq__ttl">{productitem122[3].value}</div>
			<div class="faq__close"></div>
		</div>
		<div class="faq__answer" style={{display: 'none'}}>
			<div class="faq__txt">
      {productitem122[4].value}
			</div>
		</div>
	</div>)
                  })} 


</div>


  </div>
      </div>
    </div>
  </div>
  

<div><Footer /></div>
    </>

  );
}

export default Faq;
