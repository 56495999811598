
import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';


function Footer() {
  
  return (

    <section className="footer">
       
                <div className="container">
                
                <div className="row">
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                     <div className="left-footer">
                      <p>© 2023(KCCL). All Rights Reserved</p> | <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-and-conditions">Terms and Conditions</Link>
                     </div>
                      </div>
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <ul className="social">
                         <li className="facebook"><a href="https://www.facebook.com/KamdhenuPaint/" target="_blank"><FacebookIcon/></a></li>
                         <li className="twitter"><a href="https://twitter.com/KamdhenuPaints" target="_blank"><TwitterIcon/></a></li>
                         <li className="instagram"><a href="https://www.instagram.com/kamdhenu_paints/" target="_blank"><InstagramIcon/></a></li>
                         <li className="linkedin"><a href="https://www.linkedin.com/company/kamdhenupaintsofficial" target="_blank"><LinkedInIcon/></a></li>
                         <li className="youtube"><a href="https://www.youtube.com/channel/UClUYBJ0j-IG0-5STFS6Eohg" target="_blank"><YouTubeIcon/></a></li>
                      </ul>
                      </div>
                </div>
                </div>
    
    
    </section>
  );
}

export default Footer;
