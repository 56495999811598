import React, {Component} from "react";
import '../../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function KamodualLuxuryEmulsion() {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper text-center">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10 prhead">Kamodual Luxury Emulsion</h2>
                         <p>KamoDual Luxury Emulsion is an elastomeric, super protective 100% acrylic latex paint. Specially. formulated for exterior & interior walls. It is water resistant and protects the exterior& interior walls from dampness, algae and fungal caused by lashing rain. The elasticity of the paint ﬁlm help to bridge hairline micro cracks and also enhance the look of the walls with sweet fragrance.

</p>
                        </div>
                  </div>
                </div>
                
               
            </div>
         </section>
         <section className="procuts-info ptb-100">
         <div class="info-overlay"></div>
         <div className="container pro-bg">
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                       
                        <div className="paintdes ct2">     
                        <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Anti-Dirt-Pickup.png'} alt="Slide" className="ct-img" />
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Anti-Fading.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                    
                        
                    </div>
                   
                  </div>


                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Anti-Microbiological.png'} alt="Slide" className="ct-img" />
                      
                        </div>


                     
                      
                    </div>
                   
                  </div>
                  
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                      
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Frangance.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                     
                      
                    </div>
                   
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/High-Gloss-Icon.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/High-Opacity.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Long-Colour-Stability.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/LOW-VOC.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
            
         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Smooth-Finish.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
                     
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Stain-Resistant.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
            
            
            
                  
                  <div className="col-xl-12 col-lg-12 text-center">
                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Kamodual-Luxury-Emulsion.pdf'} download>Download PDF</a>

                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/Kamodual-Luxury-Emulsion-Technical-Literature.pdf'} download> Technical Specifications</a>
                 
                  </div>


               </div>

               </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default KamodualLuxuryEmulsion;
