import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MetaTags from 'react-meta-tags';


function Interiorange () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>   
    
    <MetaTags>
      <title>Interior Wall Paints | Colour for Home Interior - Kamdhenu Paints</title>
      <meta name="description" content="Enhance the look of your home with Kamdhenu paints - We provide the best Interior Wall Paints. Explore a wild range of colour for you home interior." />
      <meta name="keywords" content="interior colour, colour for home interior, interior wall paint" />
  </MetaTags>
  
   <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Interior Range </h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/interior-range/3D-Kamo-Hi-sheen.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamo Hi-Sheen</h5>  
                            <p>It is luxury premium quality interior emulsion paint with High Sheen & tough film. It is also anti-fungal and comes with an excellent washability. Kamo Hi Sheen also has excellent stain resistance making it the perfect choice for interior wall.


</p>                <Button type="submit" variant="contained" className="product-btn"><Link to="/Kamo-Hi-Sheen">More Info</Link></Button>
                        </div>
                    </div>
                   
                  </div>


                  
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/interior-range/3d-velvety-2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Velvety
</h5>
                         
                            <p>Velvety - the paint is suitable for plaster walls, POP surface etc and gives finish which is smooth. It is also anti-peeling while maintaining a rich sheen on the walls and the finish is silky to touch.


</p>                <Button type="submit" variant="contained" className="product-btn"><Link to="/velvety">More Info</Link></Button>
                        </div>
                    </div>
                  </div>

         
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/interior-range/Kamo-shine.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>KamoShine Advance
</h5>
                         
                            <p>Kamorich Interior Emulsion is a specially formulated value for money Acrylic Emulsion paint. It is unique product with a property of washability, unique colors with rich sheen. It offers an elegant, durable, rich sheen finish.

                           
</p>                   <Button type="submit" variant="contained" className="product-btn"><Link to="/kamoshine-advance">More Info</Link></Button>
                        </div>
                    </div>
                  </div>



                  <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/3d-sheen-n-shine.png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>Sheen N Shine Stain Free
</h5>
                     
                        <p>Sheen n Shine is specially formulated paint all special qualities altogether like Stain free, Rich Sheen, Very High Silky Smooth Finish, Low VOC for easy breathing, Lead Free, Eco-Friendly, Water Repellent Property and it is almost Low Odour Paint. This stain free interior emulsion is very useful for places like living rooms, children's room, kitchen, hospitals, nursing homes, hotels, for elderly person's room and for those having breathing problems.
</p>    

<Button type="submit" variant="contained" className="product-btn"><Link to="/sheen-n-shine-stain-free">More Info</Link></Button>


                    </div>
                </div>
                </div>



                <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/Kamorich-3d-t.png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>KamoRich</h5>
                       
                        <p>Kamorich interior emulsion is a unique product with a silky finish and gives the walls a rich sheen. The paint also has high stain resistance and comes in a variety of colours. The rich sheen gives the walls an elegant look which is also long lasting.
</p>             


<Button type="submit" variant="contained" className="product-btn"><Link to="/kamorich">More Info</Link></Button>
                    </div>
                </div>
                </div>



                <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/KamoSilky-Advance-(New-Pack).png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>KamoSilky Advance
</h5>
                      
                        <p>Kamo Silky Advance is an Interior Emulsion which has Advanced feature of Silky Touch smoothner. It is an excellent quality interior paint and is a value for money solution and provides cost effective alternative to distempers. It is washable in nature and is also an anti- fungal and anti- chalking paint.
</p>               


<Button type="submit" variant="contained" className="product-btn"><Link to="/kamosilky-advance">More Info</Link></Button>
                    </div>
                </div>
                </div>


                

                <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/3d-kamosilky.png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>KamoSilky
</h5>
                      
                        <p>Kamosilky Interior Emulsion is a value for money Interior emulsion paint. It is also very durable and lasts about 1 to 2 years more than distemper which provides the finish and durability of emulsions at a price similar to distemper. Kamosilky is an interior emulsion that can be applied on all types of smooth plasters, false ceilings, asbestos sheets, brick works, concrete blocks, fiber board etc. Kamosilky Interior Emulsion provides a smooth finish to the wall.
</p>       

       
<Button type="submit" variant="contained" className="product-btn"><Link to="/kamosilky">More Info</Link></Button>


                    </div>
                </div>
                </div>

{/*

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/interior-range/2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamo Shine
</h5>
                            <small>High Stain Resistance Luxury Interior Emulsion
</small>
                            <p>Never let stains ruin the glory of your home and walls, Kamo Shine High Stain Resistance Luxury Interior Emulsion has highly smooth finish, rich sheen and is made for places like living rooms, children’s room, kitchen, hospitals



</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>




                <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/7.png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>Kamostar
</h5>
                        <small>Interior Emulsion
</small>
                        <p>A result oriented interior emulsion, Kamostar Interior, is a perfect solution for walls that aim to shine with smooth elegance at a pocket friendly rate.

</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                    </div>
                </div>
                </div>

                <div className="col-xl-4 col-lg-4">
                <div className="paint-block">
                    <div className="paint-box">
                        
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/8.png'} alt="Slide" style={{width: '200px'}} />
                    </div>
                    <div className="paintdes">
                    <h5>Kamo Beauty
</h5>
                        <small>Smooth Interior Emulsion
</small>
                        <p>Formulated by experts to provide a smooth feather touch to the walls. a range of unique colors that are easily washable and eco-friendly.

</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                    </div>
                </div>
                </div>

  */}

               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Interiorange ;
