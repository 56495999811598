import React, {Component} from "react";
import '../../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function KamoShield() {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper text-center">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10 prhead">Kamoshield

</h2>
                         <p>Kamoshield is Economical Exterior Emulsion which comes with 3 years warranty. It is very suitable for Dry & Humid climate.</p>
                         <p>
Kamoshield is a wall coating paint for exterior walls. The acrylic co-polymer emulsion-based wall coating is best for application on exterior in dry or moderately humid areas. This coating has excellent addition to different types of surfaces like cement plasters, concrete, asbestos etc. The dry film of Kamoshield exhibits excellent resistance to chalking, flaking and weathering.

</p>
                        </div>
                  </div>
                </div>
                
               
            </div>
         </section>
         <section className="procuts-info ptb-100">
         <div class="info-overlay"></div>
         <div className="container pro-bg">
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                       
                        <div className="paintdes ct2">     
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/Economical.png'} alt="Slide" className="ct-img" />
                      
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/High-Sri-Value.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                    
                        
                    </div>
                   
                  </div>


                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/LOW-VOC.png'} alt="Slide" className="ct-img" />
                      
                      
                        </div>


                     
                      
                    </div>
                   
                  </div>
                  
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                      
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/Suitable-for-Dry-and-Humid-Climate.png'} alt="Slide" className="ct-img" />
                      
                      
                        </div>

                     
                      
                    </div>
                   
                  </div>
              
            
            
                  
                  <div className="col-xl-12 col-lg-12 text-center">
                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/Kamo-shield.pdf'} download>Download PDF</a>

                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/Kamo-shield-Technical-Literature.pdf'} download> Technical Specifications</a>
                 
                  </div>


               </div>

               </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default KamoShield;
