import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MetaTags from 'react-meta-tags';

function DualPaintSeries() {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>   
    
    <MetaTags>
      <title>Dual paints in india | Dual Paint Company - Kamdhenu Paints</title>
      <meta name="description" content="Kamdhenu Paints is the one of the best dual paints companies in India. Explore trhe wide rang of dual paints at Kamdhenu Paints. Contact Now!" />
      <meta name="keywords" content="Dual Paints, Dual paint companies, Dual paints in india" />
  </MetaTags>
  
   <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Dual Paints Series</h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualLuxuryEmulsion/3d-kamodual-luxury.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamodual Luxury Emulsion</h5>
                            <p>KamoDual Luxury Emulsion is an elastomeric, super protective 100% acrylic latex paint. Specially formulated for exterior & interior walls. It is water resistant and protects the exterior & interior walls from dampness, algae and fungal caused by lashing rain. The elasticity of the paint ﬁlm help to bridge hairline micro cracks and also enhance the look of the walls with sweet fragrance.


</p>                 <Link to="/kamodual-luxury-emulsion"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/3d-kamodual-premium.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamodual Premium Emulsion</h5>
                            <p>Kamodual Premium is a high-performance premium quality exterior & Interior paint specially designed for excellent durability under extreme tropical conditions of heat, heavy rain fall and high humidity. Kamodual Premium is based on 100% pure acrylic resin system, which consider to be the as best protection against exterior & interior degradation. 

</p>                 <Link to="/kamodual-premium-emulsion"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualGoldEmulsion/3d-kamodual-gold.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamodual Gold Emulsion</h5>
                            <p>Kamodual Gold is a premium quality exterior & Interior paint specially designed for good durability under extreme tropical conditions of heat, rain fall and humidity. Kamodual Gold is based on 100% pure acrylic resin system and considers to be the good protection for exterior & interior wall. Its light fast pigments and special additives giving good protection against fungal and algal growth for years.


</p>                <Button type="submit" variant="contained" className="product-btn">  <Link to="/kamodual-gold-emulsion">More Info</Link></Button>
                        </div>
                    </div>
                  </div>

               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default DualPaintSeries;
