import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directorrameshchandsurana() {
   useEffect(() => {
      window.scrollTo(0, 0)
  
    }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Shri Ramesh Chand Surana</h2>
                          <p>Independent Director</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/ramesh.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Shri Ramesh Chand Surana, a management post-graduate from Banaras Hindu University has to his credit more than 37 years
of rich and extensive experience in Finance, Marketing, Project Execution and plant management. He
started his career with HEG Limited in 1976 as Management Trainee immediately after his MBA from
BHU, he became President in 1997, and then, elevated to the position of CEO in 1999 in HEG Limited.</p>

<p>Being a professional business doctor having vast experience, Mr. Ramesh Chand Surana has displayed
tremendous capability with his rich diverse experience and has been serving Kamdhenu Group as an
independent Director since long and has made rich contribution in policy making and crucial decision
making for path-breaking success.</p>
                    


                  </div>
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directorrameshchandsurana;
