
import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter  } from 'react-router-dom';

import Header from './layout/Header';
import Footer from './layout/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';




const itemData = [
    {
      img: 'https://kamdhenupaints.com/images/csr/1.jpg',
    },
    {
      img: 'https://kamdhenupaints.com/images/csr/2.jpg',
    },
    {
      img: 'https://kamdhenupaints.com/images/csr/3.jpg',
    },
    {
      img: 'https://kamdhenupaints.com/images/csr/4.jpg',
    },
    {
      img: 'https://kamdhenupaints.com/images/csr/5.jpg',
    },
    {
      img: 'https://kamdhenupaints.com/images/csr/6.jpg',
    },
  ];




function About() {
  
  return (
    <>
    <div><Header/></div>
    <div className="contentblock">
        
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
               <div className="row mb-40">
                  <div className="col-xl-5 col-lg-5">
                  <img src={process.env.PUBLIC_URL + '/images/cmd.jpg'} alt="chairman" className="ch-img" />
                  </div>
                  <div className="col-xl-7 col-lg-7">
                     <div className="features__wrapper-3">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Group Chairman's Message</h2>
                           <strong>We have a dream,<br/>
A dream to create a world with limitless possibilities for everyone.
                           </strong>
                        </div>
                        <p>I&#39;m delighted to welcome you to the Kamdhenu Paints website. I sincerely hope that
the information and details provided on these pages will give you a better
understanding of the company and our products.</p>
                            <p>Kamdhenu Paints was established in 2008 as a vertical under the flagship
companyKamdhenu Limitedoperating in the business of steel manufacturing since
1995.</p>
                            <p>Kamdhenu Limited successfully pioneered the franchisee business model which
resulted in greater transparency and dynamism in the company&#39;s operations.Through
technology transfer and facility upgrades, we have been able to empower small
manufacturing units spread across a sizable portion of the country with the support of
this model. Anyone interested in partnering with us and reaping the benefits of the
novel franchisee business model will find all the necessary information here.</p>

                            <p>The revolutionary Stockyard Model is an extension of the same concept, and it
represents yet another step forward in enabling de-centralization of business
operations in stock management.</p>
                          
                     </div>
                  </div>

                  <div className="col-xl-12 col-lg-12">
                  
                            <p>Additionally, there are detailed product descriptions that show the quality
benchmarking process the Kamdhenu Group undertook to satisfy international standards and provide a greater understanding of the technical specifics.</p>
                            <p>The website also highlights our consistent growth journey over time and showcases
the exceptional accomplishments that have helped us evolve and achieve the strong
market leadership position that we enjoy today. The positive market indicators
including the Brand Awareness, Market share and Profit Margins of the company are
also illustrated with graphical visuals for easy comprehension.</p>

                            <p>This website is our window to the world where we align the vision and mission that
guides Kamdhenu Group to the milestones that we have achieved are documented for
posterity.</p>
                            <p>Kamdhenu also runs a successful steel TMT business. Complete information on
Kamdhenu’s steel products is available on <a href="https://www.kamdhenulimited.com/" target="_blank">https://www.kamdhenulimited.com/</a>

</p>
                            <p>With warm regards,

</p>
                            <p>
<b>Satish Kumar Agarwal</b><br/>
<b>Group Chairman</b></p>
                  </div>


               </div>


               <div className="row">
                
                  <div className="col-xl-6 col-lg-6">
                     <div className="features__wrapper-3">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20 mt-30">Managing Director's Message</h2>
                        
                        </div>
                        <p>Established in 2008, Kamdhenu Paints quickly became a successful vertical
contributing to the successful and thriving conglomerate known as Kamdhenu
Limited. The success of the paints business marks a major milestonetowards
accomplishing the vision of making Kamdhenu an all-solution-brand for infrastructural
and construction needs.</p>
                            <p>The production lines at Kamdhenu Paints, utilizes high precision equipment based on
latest technologies and processes to deliver best-in-class products. By adopting
modern automation processes and equipment’s backed by advanced research &amp;
development laboratories the Kamdhenu label guarantees ultimate customer
satisfaction every time for every product.</p>

                     </div>
                  </div>

                  <div className="col-xl-6 col-lg-6">
                  <img src={process.env.PUBLIC_URL + '/images/saurabh.jpg'} alt="chairman" className="wd-100" />
                  </div>

                  <div className="col-xl-12 col-lg-12">
                     <div className="features__wrapper-3">
                    
                            <p className="mt-20">‘Kamdhenu Paints’ has already established itself as a preferred brand in key markets with a wide range of decorative paint products that extends from Exterior &amp; Interior Emulsions, Acrylic Distempers, Water Based &amp; Solvent Primers, Synthetic &amp; GP Enamel, Wood Finishes, Aluminium Paints and Textured to Designer Finishes. The growth journey of Kamdhenu Paints is gaining momentum with a growing network of more than 4,000 dealers and distributors maintaining a strong presence across the country.</p>

                            <p>This website contains details of our product line-up and provides a glimpse of life at Kamdhenu Paints.</p>
                          
                            <p>With warm regards,

</p>
                            <p>
<b>Saurabh Agarwal</b><br/>
<b>Managing Director</b></p>
                     </div>
                  </div>



               </div>
               
            </div>
         </section>

         
    <section className="fix ptb-100" id="philosophy">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                            <div className="section__title-wrapper text-center">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Our Philosophy
</h2>
<p>Success is a never-ending quest. Every accomplishment leading to new milestones…
Every organization, which sets its eyes on growing big has to consistently abide by a
definite set of work-ethics to raise its credibility in the market. That&#39;s why, we at
Kamdhenu also believe in upholding a set of standards and do so in each and every area of our operations.

</p>
                        </div>



<div fxLayout='row' fxLayoutWrap fxLayoutAlign="left" align="left" class="ph-cont">

	
	<div class="ph-block" fxFlex="20%" align="center">
		<mat-card class='offer-tile'>
		 <div class="officetile">
		 
		    <div class="card-grid hover-up wow animate__ animate__fadeInUp animated">
		        <div class="text-center">
		           
                <figure> <img src={process.env.PUBLIC_URL + '/images/icon/5.png'} alt="image" />
		   </figure>
           <h6>Honesty</h6>
		         
		        </div>
		      
		    </div>
		    </div>
		</mat-card>
	</div>
 

		<div class="ph-block" fxFlex="20%" align="center">
		<mat-card class='offer-tile'>
		 <div class="officetile">
		 
		    <div class="card-grid hover-up wow animate__ animate__fadeInUp animated">
		        <div class="text-center">
		          
                <figure> <img src={process.env.PUBLIC_URL + '/images/icon/4.png'} alt="image" />
		   </figure>
           <h6>Transparency</h6>
		         
		        </div>
		      
		    </div>
		    </div>
		</mat-card>
	</div>

<div class="ph-block" fxFlex="20%" align="center">
 	 	<mat-card class='offer-tile'>
		 <div class="officetile">
		 
		    <div class="card-grid hover-up wow animate__ animate__fadeInUp animated">
		        <div class="text-center">
		           
                <figure> <img src={process.env.PUBLIC_URL + '/images/icon/3.png'} alt="image" />
		   </figure>
		         
           <h6>Commitment</h6>
		        </div>
		       
		    </div>
		    </div>
		</mat-card>
	</div>
	<div class="ph-block" fxFlex="20%" align="center">
		<mat-card class='offer-tile'>
		 <div class="officetile">
		 
		    <div class="card-grid hover-up wow animate__ animate__fadeInUp animated">
		        <div class="text-center">
                <figure> <img src={process.env.PUBLIC_URL + '/images/icon/2.png'} alt="image" />
		   </figure>
           <h6>Quality Assurance</h6>
		         
		        </div>
		    </div>
		    </div>
		</mat-card>
	</div>



		<div class="ph-block" fxFlex="20%" align="center">
		<mat-card class='offer-tile'>
		 <div class="officetile">
		 
		    <div class="card-grid hover-up wow animate__ animate__fadeInUp animated">
		        <div class="text-center">
		           
		        <figure> <img src={process.env.PUBLIC_URL + '/images/icon/1.png'} alt="image" />
		   </figure>
		         
           <h6>Customer Satisfaction</h6>
		        </div>
		      
		    </div>
		    </div>
		</mat-card>
	</div>


</div>



                  </div>
                  
                  </div>
                  
                  </div>
                  
      </section>

      
         
    <section className="fix ptb-100" id="business-model">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7 col-lg-7">
                            <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Business Model
</h2>
<p>The Kamdhenu brand&#39;s growth and success are attributed to its affordable quality,
which is the brand&#39;s main USP. Customers who buy commercial and domestic paint
across the nation have praised Kamdhenu Paints for its high-quality paint products.
This has inspired us to increase the number of paint products we provide. Along with
being easily affordable for most customers, our products also benefit from a robust
distribution network that makes them widely accessible in all geographical areas.
</p>
                        </div>
                        </div>
                        </div>
                        </div>
                        </section>


                        <section className="pb-100 fix mt-40" id="awareness">
            <div className="container">
               <div className="row text-center">
               <h2 className="section__title section__title-2 font-36 mb-40">Brand Awareness
</h2>
<p>Kamdhenu Paints fully understands the importance of brand awareness and has a
well-defined policy on leveraging different media platforms to disseminate
information about its products and services. Kamdhenu’s Central and Regional
management network oversees need based information generation through
advertisements and similar promotional campaigns.</p>
<p className="mb-40">The mediums used for media promotions include all the conventional and
unconventional mediums available today.</p>
               </div>
               <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-1 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-1.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                             Electronic Media
                           </h3>
                           <p>Airing of creatively produced product ads through electronic channels. Also promoting through internet websites, Facebook, Twitter, LinkedIn, google+ and e-mails etc.

</p>
                           </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-2 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                             Print Media
                           </h3>
                           <p>Print ads disseminating information about our products and services across the nation through Magazines, Newspapers etc.

</p></div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-3 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-3.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Outdoor Media
                           </h3>
                           <p>Hoardings, Banners, Kiosks, Wall Paintings, Bus Shelters, Translights and other outdoor media.

</p></div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-4 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                            Promotional Materials
                           </h3>
                           <p>Distribution of catalogues, brochures, visiting cards, shop branding, sample board, pen, pad, mason kit having branding of Kamdhenu products.

</p></div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-5 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                           Brand Educational Activities
                           </h3>
                           <p>Dealers & Distributor Meets, Customer Meets, Seminars, conferences, Workshops, etc.

</p></div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 f-6 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Events & Schemes
                           </h3>
                           <p>Bollywood Star Nights, Live Concerts of famous Singers, Overseas trips of dealers, Dealers’ Tours.

</p></div>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         
         <section className="fix" id="vission-mission">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6">
                            <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Our Success Story
</h2>

                        <p>Every tales of success often has a modest backstory, and ours was no different. This
narrative of perseverance and success began in 1995 when a young, innovative
individual working the production lines inspired a successful expedition into India&#39;s
steel industry with a single manufacturing operation for reinforcement steel bars in
Bhiwadi, Rajasthan. Within a short period of time, the dedicated labour and
innovation led to a turning point in Kamdhenu Group, and the business evolved into a
formidable brand in the Indian steel market.</p>
                        <p>What started as a single manufacturing unit has now expanded to more than 50
manufacturing units across the country known by the reputation of being the leading
steel manufacturer, a reputation that is being replicated for the evolving paints
business.</p>
                        <p>Established in 2008, Kamdhenu Paints is a vertical contributing to the successful and
thriving conglomerate known as Kamdhenu Limited. Kamdhenu Paints is a huge leap
forward in accomplishing the vision of making Kamdhenu an all-solution-brand for
infrastructural and construction needs. Modelled after the success of Kamdhenu’s
indigenous state-of-the-art production plant at Chopanki, Bhiwadi in Rajasthan,
Kamdhenu paints utilizes high precision equipment for its production process. By
adopting modern automation processes and equipment’s backed by advanced
research &amp; development laboratories the Kamdhenu label stands for products with
the highest quality standards that guarantees ultimate customer satisfaction every
time for every product.</p>

                        </div>
                        </div>
                        
                  <div className="col-xl-6 col-lg-6 mb-40">
                  <img src={process.env.PUBLIC_URL + '/images/k-showcase1.jpg'} alt="Slide" style={{width:'100%'}} />
                  
                  </div>
                  <div className="col-xl-12 col-lg-12 mb-40">
                           
                           <p>Making its presence felt in the market already, ‘Kamdhenu Paints’ is available in a
   wide range of decorative paint products that extends from Exterior &amp; Interior
   Emulsions, Acrylic Distempers, Water Based &amp; Solvent Primers, Synthetic &amp; GP
   Enamel, Wood Finishes, Aluminium Paints and Textured to Designer Finishes.
   Kamdhenu Paints has a strong presence of dealers and distributors across the country
   and currently has 4,000 dealers and distributors.</p>
                           <p>In the last few years the company has grown from ‘one of them’ to ‘one of the best,’
   and has been making dynamic progress with an intention of growing bigger and better
   with each achivement.</p>
                              </div>
                        </div>


                        
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                            <div className="section__title-wrapper text-center vision-block">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Our Vision
</h2>
<p>To be established as the leading manufacturer and supplier of Paint products with
following global best governance practises and our founding philosophy as the corner
stone</p>
<p>Kamdhenu Paints is India’s leading player in decorative paints segment with its own
state-of-the-art manufacturing plant in Chopanki (Rajasthan) producing Interior &amp;
Exterior Emulsions, Stainers, Colourants, Designer Paints, Construction Chemicals,
Water Proofing Chemicals and other water based speciality products. We also
outsource production of a range of products like distempers, enamel and putty, etc.
from manufacturing units in Rajasthan, Uttar Pradesh, Haryana and Punjab ensuring
best quality by deputing our own technical team.
</p>
<p>Paint, as a business, is a fast growing segment. The domestic paint industry is
estimated to be around Rs.50,000 crores and consist of decorative paint contributed
more than 75% and industrial paint segment contributed around 25%. The organized
players enjoy around 65% in the overall paint industry in India.</p>
<p>The paint industry is witnessing around 18-20% annual growth. However, at
Kamdhenu, we are expecting to clock a much faster rate at 30-40% in the coming
years.  We closed the revenue around Rs.200 crore in FY2021 and are expected to
clock around Rs 1,000 crore revenue by FY26.</p>
                        </div>
                        </div>
                        </div>


                        </div>
                        </section>

                        <section className="fix ptb-100" id="directors">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12 mb-40">
                            <div className="section__title-wrapper text-center mb-40">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Board of Directors
</h2>
<p>The people behind Kamdhenu’s success are individuals of great vision, integrity and professionalism, whose able guidance is escalating/elevating Kamdhenu to unparalleled heights of success and recognition. We are deeply honoured to have such able professionals in the helm of our affairs.

</p>




</div>

                        <div className="directorlist">
                        <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/sunil.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                             Shri Sunil Kumar Agarwal
                              <small>Chairman</small>
                           </h3>
                           <p>Shri Sunil Kumar Agarwal is one of the founding members of Kamdhenu Ventures Limited. He brings with him more than
35 years of experience in marketing which helped him build an efficient pan-India
network to help Kamdhenu reach new heights.</p>
                           <Link to="/sunil-kumar"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/saurbh.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                            Shri Saurabh Agarwal
                              <small>Managing Director</small>
                           </h3>
                           <p>Shri Saurabh Agarwal is a dynamic entrepreneur and an expert in complex plants
operations. He has been associated with Kamdhenu Ventures Limited since 2019. He is B.E. in Mechanical Engineering from R.V. College of Engineering, Bangaluru.</p>
                           <Link to="/saurabh-aggarwal"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/sachin.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Shri Sachin Agarwal
                              <small>Non-Executive Director</small>
                           </h3>
                           <p>Shri Sachin Agarwal is a BE in Industrial Engineering from R.V. College of Engineering, Bangalore. He
got his MBA in International Management from the UK. He joined Kamdhenu Ventures Limited in 2019 and
has been a vital part of the company&#39;s management team.</p>
                           <Link to="/sachin-agarwal"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/MA_ID.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Shri Madhusudan Agarwal
                              <small>Independent Director</small>
                           </h3>
                           <p>Shri Madhusudan Agarwal, University of Delhi alumnus, is a fellow member of The Institute of Chartered
Accountants of India (ICAI) since 1987. He has more than three decades of professional experience in
the field of audit, management consultancy, tax, company law matters and worked with various
corporates across industries including steel, print media, healthcare, digital media, financial services,
textile, real estate, automobiles etc. </p>
                           <Link to="/madhusudan-agarwal"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/ramesh.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Shri Ramesh Chand Surana
                              <small>Independent Director</small>
                           </h3>
                           <p>Shri Ramesh Chand Surana, a management post-graduate from Banaras Hindu University has to his credit more than 37 years
of rich and extensive experience in Finance, Marketing, Project Execution and plant management. He
started his career with HEG Limited in 1976 as Management Trainee immediately after his MBA from
BHU. he became President in 1997, and then, elevated to the position of CEO in 1999 in HEG Limited.</p>
                           <Link to="/ramesh-chand-surana"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 text-center mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/directors/nishal.jpg'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Smt. Nishal Jain
                              <small>Independent Director</small>
                           </h3>
                           <p>Smt. Nishal Jain, PGDHR from Institute of Management and Technology, Kolkata is a seasoned
professional in the field of Human Resources Management. She has over 15 years of professional
experience in areas like Recruitment, Manpower Planning, Compensation Planning, Appraisals,
Employee Engagement &amp; related HRBP role. She had worked with various industries handling a team of
HR executives &amp; looked after the end to end recruitment. </p>
                           <Link to="/nishal-jain"><Button variant="contained">Read More</Button></Link>
                           </div>
                     </div>
                  </div>



               </div>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        </section>



                        <section className="fix ptb-100" id="committies">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12 mb-40">
                            <div className="section__title-wrapper text-center mb-40">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Board Committees
</h2>


</div>
</div>

<div className="tableblock">
<div className="row">
                  <div className="col-xl-6 col-lg-6 mb-40">
    <h6>Audit Committee</h6>
                            <table cellPadding={0} cellSpacing={0} width={100}>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Shri Madhusudan Agarwal</td>
                                    <td>Chairman</td>                                   
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Shri Saurabh Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Shri Ramesh Chand Surana</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Smt. Nishal Jain</td>
                                    <td>Member</td>                                   
                                </tr>
                                </table>
                                </div>
                                <div className="col-xl-6 col-lg-6 mb-40">
                                 <h6>Risk Management Committee</h6>
                            <table cellPadding={0} cellSpacing={0} width={100}>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                </tr>
                              
                                <tr>
                                    <td>1</td>
                                    <td>Shri Sunil Kumar Agarwal</td>
                                    <td>Chairman</td>                                   
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Shri Saurabh Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Shri Madhusudan Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Smt. Nishal Jain</td>
                                    <td>Member</td>                                   
                                </tr>
                               
                                <tr>
                                    <td>5</td>
                                    <td>Shri Vineet Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                </table>
                                </div>

                                <div className="col-xl-6 col-lg-6 mb-40">
    <h6>Stakeholders Relationship Committee</h6>
                            <table cellPadding={0} cellSpacing={0} width={100}>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Shri Madhusudan Agarwal</td>
                                    <td>Chairman</td>                                   
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Shri Saurabh Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Smt. Nishal Jain</td>
                                    <td>Member</td>                                   
                                </tr>
                                </table>
                                </div>



                                <div className="col-xl-6 col-lg-6 mb-40">
    <h6>Nomination and Remuneration Committee</h6>
                            <table cellPadding={0} cellSpacing={0} width={100}>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Designation</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>Shri Ramesh Chand Surana</td>
                                    <td>Chairman</td>                                   
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Shri Madhusudan Agarwal</td>
                                    <td>Member</td>                                   
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Smt. Nishal Jain</td>
                                    <td>Member</td>                                   
                                </tr>
                                </table>
                                </div>



                              


                                </div>
                                </div>
</div>
</div>
</section>


         
<section className="fix ptb-100" id="csr">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                            <div className="section__title-wrapper text-center mb-40">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Corporate Social Responsibility

</h2>
<p>Our society is our home. With this belief Kamdhenu Limited attempts towards the well being of the society. What Kamdhenu is today is the assimilation of love, affection, liking and acceptance received from society. We take it to be our responsibility to reciprocate this warmth by bringing about positive changes in the society. Our endeavor to support the society comes into form in the shape of 'Kamdhenu Jeevandhara'. Guided by the visionary Chairperson Mrs. Radha Agarwal, Kamdhenu Jeevandhara "tends to build a platform in order to contribute towards the holistic wellbeing of humanity in every small measure within capacity". Under this foundation Kamdhenu focuses on the upliftment of the underprivileged children. These deprived little ones are encouraged for education with schemes of free mid-day meals and learning kits. In order to make these children competent to face the competitive world they are imparted with computer education as well. Efforts invested in this direction aims to achieve the larger perspective of eradicating child labor.

</p>
<p>'Kamdhenu Jeevandhara' is also instrumental in supporting the physically challenged individuals of the society. It organizes various welfare camps for handicaps wherein, they are provided with free artificial limb, wheel chairs, walkers, polio calipers and other body-aid instruments. Medicines & Counseling to the patient and their families are also delivered absolutely free of charge. Kamdhenu Jeevandhara associates with numerous NGOs and other autonomous bodies to help the physically challenged through different means of relief.

</p>
<p>Being sensitive to the impending threat of global warming 'Kamdhenu Jeevandhara' runs the unique 'Green India' campaign, under which every Kamdhenu dealers & distributors plants a minimum of 5 saplings a year and nurtures them. Steps are also taken to shun industrial discharge and unhealthy ways of residue or garbage disposal thereby beating down soil pollution.

</p>
                        </div>

                        <div className="csr-list">

                        <ImageList sx={{ }} cols={3} rowHeight={250}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
                        </div>

                        </div>
                        </div>
                        </div>
                        </section>


                        
<section className="fix" id="awards">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                            <div className="section__title-wrapper text-center mb-40">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Awards and Achievement


</h2>
<p>We truly believe that Customer delight and satisfaction are the true award we vouch for and our efforts to meet the customers’ expectation have brought many accolades to us.

</p>
                        </div>

                        <div className="award-list">
                      
                              
                        <div className="row">
                          
                                <div className="col-xl-4 col-lg-4">
                            <img src={process.env.PUBLIC_URL + '/images/awards/2.jpg'} alt="Slide" />
                                </div>  


                                <div className="col-xl-4 col-lg-4">
                            <img src={process.env.PUBLIC_URL + '/images/awards/3.jpg'} alt="Slide" />
                                </div>  


                                <div className="col-xl-4 col-lg-4">
                            <img src={process.env.PUBLIC_URL + '/images/awards/4.jpg'} alt="Slide" />
                                </div>  


                                <div className="col-xl-4 col-lg-4">
                            <img src={process.env.PUBLIC_URL + '/images/awards/5.jpg'} alt="Slide" />
                                </div>  


                                <div className="col-xl-4 col-lg-4">
                            <img src={process.env.PUBLIC_URL + '/images/awards/6.jpg'} alt="Slide" />
                                </div>  

                                </div>  
                        </div>

                        </div>
                        </div>
                        </div>
                        </section>



    </div>
    <div><Footer/></div>
    </>
  );
}

export default About;
