import React, { Component, useState, useEffect } from "react";
import './index.css';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer'
import $ from 'jquery';

import Button from '@mui/material/Button';

function News() {
   const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
   };
   const [productdata, setProductData] = useState([]);
   const productdata2 = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": {}, "isDownload": false, "index": 1, "pgSize": 500, "templateID": 4 }
   function ProductData1() {
     fetch(
       "https://api.dialerp.com/api/api/getTemplateDataList",
       {
 
         method: "POST",
         headers: {
           "Authorization-Token":
             "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
           Accept: "application/json, text/plain",
           "Content-Type": "application/json;charset=UTF-8",
         },
         body: JSON.stringify(productdata2),
 
       }
     ).then((result) => {
       result.json().then((resp) => {
         setProductData(resp);
         console.log(resp, 'News data')
       });
     });
   }
   useEffect(() => {
     ProductData1();
 
   }, []);

   return (
      <>    <Header />
         <div className="contentblock blog news" style={{ background: '#f8f8f8' }}>
            <div className="directorlist container">
               <div className="row">

                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                     <h5>News</h5>
                  </div>

                  <React.Fragment>
                  { productdata?.response?.records?.map((productitem, i) => {
                    return (
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" 
                        onClick={() => openInNewTab(productitem[5].value)}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src= {productitem[4].value} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                              {productitem[3].value}
                              </h3>
                           </div>
                        </div>
                     </div>
                    )})}
                     {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                        <div className="features__item-3 text-center mb-30" onClick={() => openInNewTab('https://economictimes.indiatimes.com/industry/cons-products/paints/kamdhenu-eyes-rs-1000-crore-revenue-from-paints-section-in-next-5-years-cmd/articleshow/82494376.cms')}>
                           <div className="features__icon-3 mb-30 p-relative">
                              <img src={process.env.PUBLIC_URL + '/images/101-1.jpg'} alt="Slide" />
                           </div>
                           <div className="features__content-3">
                              <h3 className="features__title features__title-3">
                                 The Economic Times
                              </h3>


                           </div>
                        </div>
                     </div> */}
                  </React.Fragment>

               </div>

            </div>
         </div>


         <div><Footer /></div>
      </>

   );
}

export default News;
