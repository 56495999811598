import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Woodfinish () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Wood Finish</h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/wood-coating-products/1.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamwood Melamyne</h5>  
                            <p>Kamwood Melamyne is solvent based paint that can be used on all kinds of wood and veneers. Kamwood Melamyne is specifically formulated to protect as well as decorate the wooden surfaces. It has the ability to protects the interior of wood for many years.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/wood-coating-products/2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamwood Polyurethane Wood Finish

</h5>
                            <p>Kamwood Polyurethane Wood Finish is a single pack Polyurethane modified paint to give wooden surface and excellent finish. It enhances the natural look of wood and prevents the wooden surface from normal wear and tear.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/wood-coating-products/3.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamwood NC Sanding Sealer
</h5>
                            <p>A single component, nitrocellulose based sanding sealer. A fast drying sanding sealer for solid and veneered timber or Medium Density Fibreboard (MDF). NC Sanding Sealer is design to seal and prime, sand easily and allow for excellent adhesion


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/wood-coating-products/4.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamoclear Lacquer

</h5>
                            <p>Kamoclear Lacquer is a high quality quick drying clear lacquer paint usinf Nitro Cellulose as the base solvent. It is ideal for interior wooden furniture. Kamoclear Lacquer gives outstanding coverage and is highly economical in use.

</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/wood-coating-products/5.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamothin Thinner</h5>
                            <p>Kamothin Thinner is an all-purpose thinner which can be used as a solvent for oil based paints additionally you may also use it to clean up the excess paint.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                




               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Woodfinish ;
