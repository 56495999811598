import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directormadhusudanagarwal() {
   useEffect(() => {
      window.scrollTo(0, 0)
  
    }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36"> Shri Madhusudan Agarwal
</h2>
                          <p>Independent Director</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/MA_ID.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Shri Madhusudan Agarwal, University of Delhi alumnus, is a fellow member of The Institute of Chartered
Accountants of India (ICAI) since 1987. He has more than three decades of professional experience in
the field of audit, management consultancy, tax, company law matters and worked with various
corporates across industries including steel, print media, healthcare, digital media, financial services,
textile, real estate, automobiles and IT services etc. He is a senior partner of Doogar &amp; Associates,
Chartered Accountants, New Delhi.</p>
<p>Shri Madhusudan Agarwal has authored several books of professional interest and also a regular
contributor of Articles in various journals and magazines published by the ICAI, Money Matter and Capital
Market etc.</p>
                    


                  </div>
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directormadhusudanagarwal;
