import React from "react";

function NotFound() {
  return (
    <div className="main-wrapper error-page" style={{ textAlign: "center" }}>
  
    <div className="error-img">
      <img
        className="img-fluid"
        src="./images/error-404.png"
        alt="img"
        style={{ width: "50%" }}
      />
    
    </div>
    <h2 style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
      404 Oops! Page Not Found
    </h2>
    <p style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
      This page doesn't exist or was removed! We suggest you back to home.
    </p>
    <a
      href="https://kamdhenupaints.com/"
      className="btn btn-primary"
      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
    >
      <i className="feather-arrow-left-circle me-2" />
      Back to Home
    </a>
  </div>
  
  );
}

export default NotFound;
