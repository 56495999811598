import React, { Component, useState, useEffect } from "react";
import '../index.css';
import { BrowserRouter, Link, NavLink, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { HashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {

  const [name, setName] = useState("");
  const [emailID, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [remarks, setMessage] = useState("");


  let navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=(countryCode)&mobile=(mobile)&name=(name)&emailID=(emailID)&remarks=(remarks, type)", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: emailID,
          mobile: mobile,
          remarks: remarks,
        }),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setName("");
        setEmail("");
        setMobileNumber("");
        setMessage("");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
    let path = `newPath`;
    navigate('.../thankyou');
  };

  const [type, setType] = React.useState('');

  const handleChange = (event) => {
    setType(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [SubSubCategoryata, setSubSubCategoryata] = useState([]);
  const filterPra =
  {
    "pgSize": 1000,
    "index": 1,
    "toEmailID": "icici.amitsingh@gmail.com",
    "tabErpID": 1481,
    "useAtlusSearch": true,
    "filters": "{}",
    "viewFormat": "columnname",
  }
  function fun_SubSubCategoryList() {
    fetch(
      "https://kamdhenu.azurewebsites.net/api/Dynamic/GetRecords",
      {

        method: "POST",
        headers: {
          "Authorization-Token": "kQW2JTvGgckCcC6j5nW0k/nKxLljH0p9EplJQnpr/ksFwPuIefHCwZB8JNE4d4QHaDaif/Ky7T8lgpc7BflM46RCez8w5ERiH3D3j6XtHEkycmM29WqzJqa/t4g/s2WL0ibxHdwG0pV4hTRMrZTUEfxLfv9nVMjLJNCQ54j0amMUo5tJSHIQ2jEsZSTe/IXAmeCFP3ZPryPxK7LSoAO4Q3vGt9EXSPT+sLMFYbnmBObBrK9qFRIjHRoLLcjY7UkJ",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(filterPra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setSubSubCategoryata(resp);
      });
    });
  }
  useEffect(() => {
    fun_SubSubCategoryList();
  }, [0]);

  return (

    <Box>

      <AppBar position="static" className='app-tool'>
        <Toolbar className="headtool">
          <Link to="/"><img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Slide" style={{ width: '145px' }} />
          </Link>

          <div className="menuu-block">
            <header className="header">

              <div className="">

                <Navbar collapseOnSelect expand="lg" variant="light">
                  <Container>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="me-auto">
                        <Nav.Link> <Link to="/" activeclassname="active pd-0">Home</Link></Nav.Link>

                        <NavDropdown title="About Us" id="collasible-nav-dropdown">
                          <NavDropdown.Item> <HashLink smooth to="/about#chairman-msg" activeclassname="active">Chairman's Message</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#philosophy" activeclassname="active">Our Philosophy</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#business-model" activeclassname="active">Business Model</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#awareness" activeclassname="active">Brand Awareness</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#vission-mission" activeclassname="active">Our Success Story/ Our Vision</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#directors" activeclassname="active">Board of Directors</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#committies" activeclassname="active">Board Comittees</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#csr" activeclassname="active">CSR Initiatives</HashLink></NavDropdown.Item>
                          <NavDropdown.Item> <HashLink smooth to="/about#awards" activeclassname="active">Awards & Acknowledgements</HashLink></NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Products" id="collasible-nav-dropdown">
                         <NavDropdown.Item> <Link to="/dual-paints-series"> Dual Paints Series</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/designer-range"> Designer Finishes</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/exterior-range"> Exterior Emulsion Range </Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/interior-range"> Interior Emulsion Range</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/distemper-range"> Acrylic Washable Distemper </Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/premium-enamel-range"> Premium Enamel Paint</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/wood-coating-products"> Wood Coating Products</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/water-proofing-solutions"> Water Proofing Solutions</Link></NavDropdown.Item>

                        </NavDropdown>
                        <NavDropdown title="Catalogue" id="collasible-nav-dropdown">
                          {SubSubCategoryata?.response?.records
                            ?.sort((a, b) => a["SeqNo*"] - b["SeqNo*"])?.map((data, i) => (
                              <React.Fragment key={i}>
                                <NavDropdown.Item> <Link to={`/Product/${data["SubCategoryID"]}`} > {data["SubCategory"]}</Link></NavDropdown.Item>
                              </React.Fragment>
                            ))} 
                          {/* <NavDropdown.Item> <Link to="/dual-paints-series"> Dual Paints Series</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/designer-range"> Designer Finishes</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/exterior-range"> Exterior Emulsion Range </Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/interior-range"> Interior Emulsion Range</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/distemper-range"> Acrylic Washable Distemper </Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/premium-enamel-range"> Premium Enamel Paint</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/wood-coating-products"> Wood Coating Products</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/water-proofing-solutions"> Water Proofing Solutions</Link></NavDropdown.Item> */}

                        </NavDropdown>
                        <NavDropdown title="Be Our Partner" id="collasible-nav-dropdown">
                          <NavDropdown.Item> <Link to="/dealer">Be Our Dealer</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/distributor">Be Our Distributor</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/contractor">Be Our Contractor</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/vendor">Be Our Vendor</Link></NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link><Link to="/contact" activeclassname="active">Contact Us</Link></Nav.Link>

                        <NavDropdown title="Investor Zone" id="collasible-nav-dropdown">
                          <NavDropdown.Item> <Link to="/notice-to-shareholders">Notice to Shareholders</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/investor-zone">KVL Code Of Conduct & Policies</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/financial-results">Financial Results</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/annual-results">Annual Report</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/annual-return">Annual Return</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/shareholding-pattern">Shareholding Pattern</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/investor-information">Investor Information</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/presentation">Presentation / Press Release/ Transcript</Link></NavDropdown.Item>

                          <NavDropdown.Item> <Link to="/stock-exchange-disclosures">Stock Exchange Disclosures</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/subsidiary-financials">Subsidiary Financials</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/scheme-of-arrangement">Scheme of Arrangement</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/investor-help-desk">Investor Help Desk</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/general-meeting">General Meeting</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/disclosures-under-regulation46">Disclosures under Regulation 46 of the LODR</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/disclosures-under-regulation30">Disclosure under Regulation 30 of LODR</Link></NavDropdown.Item>
                          <NavDropdown.Item> <Link to="/listing-of-shares">Listing of Shares</Link></NavDropdown.Item>

                        </NavDropdown>
                        <Nav.Link><Link to="/news" activeclassname="active">News & Media</Link></Nav.Link>
                        <Nav.Link><Link to="/blog" activeclassname="active">Blog</Link></Nav.Link>
                        <Nav.Link><Link to="/faq" activeclassname="active">FAQ</Link></Nav.Link>
                        <Nav.Link><Link to="/pickyourcolors.html" activeclassname="active">Shade Cards</Link></Nav.Link>
                        <Nav.Link><Link to="" className="book" onClick={handleClickOpen}>Book Free Consultant</Link></Nav.Link>

                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>

              </div>

              <div style={{ width: '600px' }}>
                <Dialog
                  fullScreen={fullScreen} className='req-dialog'
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                > <div className='close'>
                    <CloseIcon onClick={handleClose} />
                  </div>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} className="">
                    <h3>Book a Free Consultant</h3>
                    <p className="dilogcont">If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.

                    </p>
                    <Grid item xs={12} sm={12} md={12} className='right-panel'>
                      <form onSubmit={handleSubmit}>
                        <form className="application-form">
                          <DialogContent>
                            <DialogContentText>
                              <TextField id="standard-basic" label="Your Name" variant="standard" required style={{ width: '100%', marginBottom: '10px' }} value={name} onChange={(e) => setName(e.target.value)} /><br />
                              <TextField id="standard-basic" label="Your Email" variant="standard" required style={{ width: '100%', marginBottom: '10px' }} value={emailID} onChange={(e) => setEmail(e.target.value)} /><br />

                              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} className="">

                                <Grid item xs={12} sm={12} md={4} className=''>
                                  <TextField
                                    required
                                    id="standard-required"
                                    label="Country Code"
                                    defaultValue="+91"
                                    variant="standard" style={{ width: '100%', marginBottom: '10px' }}
                                  /><br />

                                </Grid>
                                <Grid item xs={12} sm={12} md={8} className=''>
                                  <TextField id="standard-basic" label="Your Mobile" variant="standard" required style={{ width: '100%', marginBottom: '10px' }} value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} /><br />

                                </Grid>
                              </Grid>
                              <FormControl fullWidth className="select-box">
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={type}
                                  label="Age"
                                  onChange={handleChange} variant="standard"
                                >
                                  <MenuItem value={10}>Residential</MenuItem>
                                  <MenuItem value={20}>Commercial</MenuItem>
                                  <MenuItem value={30}>Individual</MenuItem>
                                </Select>
                              </FormControl>
                              <TextField id="standard-basic" label="Message"
                                multiline rows={3} variant="standard" required style={{ width: '100%', marginBottom: '10px' }} value={remarks} onChange={(e) => setMessage(e.target.value)} />

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions className='login-btn'>


                            <div className="col-lg-12 text-center">
                              <Button type="submit" variant="contained">Send Now</Button>

                            </div>

                          </DialogActions></form>
                      </form>

                    </Grid>

                  </Grid>
                </Dialog>
              </div>
            </header>
          </div>

        </Toolbar>
      </AppBar>


    </Box>
  );
}

export default Header;
