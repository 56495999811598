import React, {Component, useState} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import MetaTags from 'react-meta-tags';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Pickyourcolors() {

  return (
    <> 
    
    <MetaTags>
      <title>Best Colours for House | Home Wall Colour - Kamdhenu Paints</title>
      <meta name="description" content="Kamdhenu Paints is available in a wide range of decorative paint products that extends from Exterior & Interior. We have wide range of colours forn your home's wall." />
      <meta name="keywords" content="best colour for house, wall colour, home colour" />
  </MetaTags>
  
     <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100 pick-colors">
            <div className="container">
                
               <div className="row mb-80">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Explore our Range of Colours
</h2>

                          <p style={{color:'#ff0000', marginBottom:'0px'}}>Click on any below Thumbnail to download the shade card of mention product.

</p>
                            <small style={{fontWeight:'500'}}>(kindly note that Shades shown are for indication purposes only, actual shade may vary on mobile phone so please use our shade fandeck before tinting.

</small>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-12 col-lg-12">

                  <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamdhenu-interior-shade-card.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/1.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Interior Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamdhenu-exterior-shade-card.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/2.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Exterior Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamdhenu-kamodual.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/3.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamodual Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamdhenu-enamel.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/4.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Enamel Shades
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamdhenu-distemper.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/5.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Distemper Shades

                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Kamdhenu-kamocem.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/6.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamocem Shade Card
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/product-catalogue.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/9.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Product Catalogue
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Technical-Datasheet.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/technical-literature.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Technical Literature
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Exterior-Pocket-Color-Book-(Kamdhenu).pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/8.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Exterior Colour Combination Guide
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item><Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/interior-booklet.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/7.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Interior Colour Combination Guide
                                    </Typography>
                                  
                                </CardContent>
                                </Card></Item>
                        </Grid>


                        
                       
                  </Grid>

                  <Grid container spacing={2}>
                        <Grid item md={12} style={{margin:'50px 0px 8px 0px'}}>
                            <h5>Explore our Exterior Range</h5>
                        </Grid>
                    </Grid>
                  <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Weather-Supreme.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/weather-supreme.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Weather Supreme
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Weather-Classic-Max.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/weather-classic-max.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Weather Classic Max
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/Kamoshield.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/kamoshield.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamoshield
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                       
                  </Grid>


                  <Grid container spacing={2}>
                        <Grid item md={12} style={{margin:'50px 0px 8px 0px'}}>
                            <h5>Explore our Interior Range</h5>
                        </Grid>
                    </Grid>
                  <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamo-hi-sheen.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/kamo-hi-sheen.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamo Hi Sheen
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/velvety.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/velvety.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Velvety
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/sheen-n-shine.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/sheen-n-shine.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Sheen n Shine
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                       
                  </Grid>


                  <Grid container spacing={2}>
                        <Grid item md={12} style={{margin:'50px 0px 8px 0px'}}>
                            <h5>Explore our Dual Product Range</h5>
                        </Grid>
                    </Grid>
                  <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamodual-luxury-emulsion.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/kamodual-luxury-emulsion.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamodual Luxury Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamodual-premium-emulsion.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/kamodual-premium-emulsion.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamodual Premium Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={process.env.PUBLIC_URL + '/pdf/kamodual-gold-emulsion.pdf'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/kamodual-gold-emulsion.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Kamodual Gold Emulsion
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                       
                  </Grid>


                  <Grid container spacing={2}>
                        <Grid item md={12} style={{margin:'50px 0px 8px 0px'}}>
                            <h5>Explore Self Explanatory Videos</h5>
                        </Grid>
                    </Grid>
                  <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={'https://www.youtube.com/watch?v=WgASGWNZy9Y}'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/12.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Introduction of Kamo Hi Sheen
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={'https://www.youtube.com/watch?v=Z_bii472MvU'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/13.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Introduction of Weather Classic Max

                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                        <Grid item md={4}>
                            <Item>
                            <Card sx={{ maxWidth: 345 }}>
                                <a href={'https://www.youtube.com/watch?v=-AmUvON-LQE'} target="_blank">  <CardMedia
                                    component="img"
                                    image="/images/shades/14.jpg"
                                    alt="green iguana"
                                /></a>
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                    Introduction of Weather Supreme
                                    </Typography>
                                  
                                </CardContent>
                                </Card>

                            </Item>
                        </Grid>
                       
                  </Grid>




                 </div>
                  

             
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Pickyourcolors;
