import React, { Component, useState, useEffect } from "react";
import './index.css';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer'
import $ from 'jquery';

import Button from '@mui/material/Button';

function Blog() {
   const [blogData, setblogData] = useState([]);
   const filterPra = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
   function fun_blogDataList() {
      fetch(
         "https://api.dialerp.com/api/api/getTemplateDataList",
         {

            method: "POST",
            headers: {
               "Authorization-Token":
                  "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
               Accept: "application/json, text/plain",
               "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(filterPra),

         }
      ).then((result) => {
         result.json().then((resp) => {
            setblogData(resp);
         });
      });
   }
   useEffect(() => {
      fun_blogDataList();
   }, [0]);

   return (
      <>    <Header />
         <div className="contentblock blog" style={{ background: '#f8f8f8' }}>
            <div className="directorlist container">
               <div className="row">

                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                     <h5>Blogs</h5>
                  </div>
                  {blogData?.response?.records?.map((blog, i) => (
                     <React.Fragment key={i}>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                           <div className="features__item-3 text-center mb-30">
                              <div className="features__icon-3 mb-30 p-relative">
                                 <img src={blog[5].value} style={{ height: "145px" }} alt="Slide" />
                              </div>
                              <div className="features__content-3">
                                 <h3 className="features__title features__title-3">
                                    {blog[3].value}
                                 </h3>
                                 <Link to={`/blog-details/${blog[0].value}`}><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                                {/*   <div dangerouslySetInnerHTML={{ __html: blog[4].value }} />
                                <p> {blog[4].value}</p> */}

                              </div>
                           </div>
                        </div>
                     </React.Fragment>
                  ))}
               </div>

            </div>
         </div>


         <div><Footer /></div>
      </>

   );
}

export default Blog;
