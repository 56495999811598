import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import GetAppIcon from '@mui/icons-material/GetApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';



function CodeofConduct() {
  
  return (
    <>    <Header/>
    <div className="contentblock">
        <div className="container">
               <div className="row">
                   
                     <div className="col-lg-12 col-md-12">
                            <div className="section__title-wrapper">
                              <h2 className="section__title font-36">Code of Conduct</h2>
                           </div>
                        <div className="tableblock" style={{height:'500px'}}>
                        
                        </div>
                     </div>     
                </div>                
        </div>
    </div>

<div><Footer /></div>
    </>

  );
}

export default CodeofConduct;
