import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Waterproofing () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Water Proofing Solutions</h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/water-proofing-solutions/1.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamocrete CRP
</h5>  
<small>Concrete repair Product
</small>
                            <p>KAMOCRETE CRP is an Acrylic Polymer Based Liquid to give a waterproof protective system in cementitious composite for protective coating. Kamocrete CRP is extremely useful in water proof coating, overlays cement sand repair mortars, and you can also use it as bonding agent.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/water-proofing-solutions/2.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamofin 2 K


</h5>
<small>Concrete repair Product
</small>
                            <p>KAMOFIN is a two-in-one product for polymer modified cementitious composite used as a water proof protective coating when it hardens develops excellent bonding property with most of the building material. The Kamofin develops slightly flexible film, which seals hair line cracks


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/water-proofing-solutions/3.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamoproof ALW

</h5>
                            <p>Kamoproof ALW is a normal setting Liquid Integral water proofing compound for cement mortar and concrete. It is very useful in waterproofing of concrete and cement sand mortar used in Basement, Roof Slabs, Bathroom, Water retaining structure, sumps


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/water-proofing-solutions/4.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamo Floor Coat</h5>
<small>Fast Track Emulsion for Exterior Floor / Tiles
</small>
                            <p>Kamo Floor Coat (KFC) is a specialized paint (based on Fast Track Emulsion) specially formulated for Exterior / terrace floor tiles, cement tiles and any walkways or plain cemented surfaces.


</p>                <Button type="submit" variant="contained" className="product-btn">More Info</Button>
                        </div>
                    </div>
                  </div>
                  

                




               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Waterproofing ;
