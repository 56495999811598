
import React, {Component} from "react";
import '../../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function SheenShineStainFree() {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper text-center">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10 prhead">Sheen N Shine Stain Free</h2>
                         <p>Sheen n Shine is specially formulated paint all special qualities altogether like Stain free, Rich Sheen, Very High Silky Smooth Finish, Low VOC for easy breathing, Lead Free, Eco-Friendly, Water Repellent Property and it is almost Low Odour Paint. This stain free interior emulsion is very useful for places like living rooms, children's room, kitchen, hospitals, nursing homes, hotels, for elderly person's room and for those having breathing problems.
</p>
                        </div>
                  </div>
                </div>
                
               
            </div>
         </section>
         <section className="procuts-info ptb-100">
         <div class="info-overlay"></div>
         <div className="container pro-bg">
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                       
                        <div className="paintdes ct2">     
                        <img src={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/Eco-Friendly.png'} alt="Slide" className="ct-img" />
                      
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/High-Stain-Resistant.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                    
                        
                    </div>
                   
                  </div>


                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/LOW-VOC.png'} alt="Slide" className="ct-img" />
                      
                        </div>


                     
                      
                    </div>
                   
                  </div>
                  
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                      
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/Rich-Sheen.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                     
                      
                    </div>
                   
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/Water-Repellent.png'} alt="Slide" className="ct-img" />
                      
                        </div>


                       
                    </div>
                   
                  </div>
            

            
            
                  
                  <div className="col-xl-12 col-lg-12 text-center">
                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/Sheen-N-Shine-Stain-Free.pdf'} download>Download PDF</a>

                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/interior-range/sheen-shine-stain-free/Sheen-N-shine-Technical-Literature.pdf'} download> Technical Specifications</a>
                 
                  </div>


               </div>

               </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default SheenShineStainFree;
