import React, {Component, useState} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Vendor() {
  
  
        const [name, setname] = useState("");
        const [address, setAddress] = useState("");
        const [city, setCity] = useState("");
        const [state, setState] = useState("");
        const [zip, setZip] = useState("");
        const [mobile, setMobileNumber] = useState("");
        const [emailID, setEmail] = useState("");
        const [fax, setFax] = useState("");
        const [presidentname, setPresidentname] = useState("");
        const [presidentnumber, setPresidentnumber] = useState("");
        const [presidentfax, setPresidentfax] = useState("");
        const [presidentexperience, setPresidentexperience] = useState("");
        const [financename, setFinancename] = useState("");
        const [financefax, setFinancefax] = useState("");
        const [financenumber, setFinancenumber] = useState("");
        const [financeexperience, setFinanceexperience] = useState("");
        const [salesname, setSalesname] = useState("");
        const [salesfax, setSalesfax] = useState("");
        const [salesumber, setSalesnumber] = useState("");
        const [salesexperience, setSalesexperience] = useState("");
        const [purchasingname, setPurchasingname] = useState("");
        const [purchasingfax, setPurchasingfax] = useState("");
        const [purchasenumber, setPurchasenumber] = useState("");
        const [purchasingexperience, setPurchasingexperience] = useState("");
        const [contactpersonname, setContactpersonname] = useState("");
        const [contactpersonnumber, setContactpersonnumber] = useState("");
        const [contactpersonfax, setContactpersonfax] = useState("");
        const [contactpersonexperience, setContactpersonexperience] = useState("");
        const [owner, setOwner] = useState("");
        const [yearestablished, setYearestablished] = useState("");
        const [remarks, setMessage] = useState("");
        const [nextyearforcast, setNextyearforcast] = useState("");
        const [currentyear, setCurrentyear] = useState("");
        const [previousyear, setPreviousyear] = useState("");
        const [previousthreeyear, setPreviousthreeyear] = useState("");
        const [customer1, setCustomer1] = useState("");
        const [customer2, setCustomer2] = useState("");
        const [customer3, setCustomer3] = useState("");
        const [customer4, setCustomer4] = useState("");
        const [topcustomer1, setTopcustomer1] = useState("");
        const [topcustomer2, setTopcustomer2] = useState("");
        const [topcustomer3, setTopcustomer3] = useState("");
        const [topcustomer4, setTopcustomer4] = useState("");
        const [ISO9000registrationdate, setISO9000registrationdate] = useState("");
        const [ISO9000registrar, setISO9000registrar] = useState("");
        const [registrationTS16949date, setRegistrationTS16949date] = useState("");
        const [TS16949registrar, setTS16949registrar] = useState("");
        
      
        let navigate = useNavigate(); 
      
      
  let handleSubmitt = async (e) => {
        e.preventDefault();
       
        fetch
         ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks+', Address:'+address+', City:'+city+', State:'+state+', Zip:'+zip+', Fax:'+fax+', Management - President: Name:'+presidentname+', Number:'+presidentnumber+', Fax:'+presidentfax+', Experience:'+presidentexperience+', Management - Finance: Name:'+financename+', Number:'+financenumber+', Fax:'+financefax+', Experience:'+financeexperience+', Management - Sales: Name:'+salesname+', Number:'+salesumber+', Fax:'+salesfax+', Experience:'+salesexperience+', Management - Purchasing: Name:'+purchasingname+', Number:'+purchasenumber+', Fax:'+purchasingfax+', Experience:'+purchasingexperience+', Management - Contact Person: Name:'+contactpersonname+', Number:'+contactpersonnumber+', Fax:'+contactpersonfax+', Experience:'+contactpersonexperience+', Company Background: Owner:'+owner+', Years Established:'+yearestablished+', Sales (Rupees): Next Year Forecast:'+nextyearforcast+', Current Year:'+currentyear+', Previous Year:'+previousyear+', Previous Three Year:'+previousthreeyear+', Customers: Customer 1:'+customer1+', Customer 2:'+customer2+', Customer 3:'+customer3+', Customer 4:'+customer4+', Top 4 Customers: Customer 1'+topcustomer1+', Customer 2:'+topcustomer2+', Customer 3:'+topcustomer3+', Customer 4:'+topcustomer4+', Quality System : ISO9000 : Registration Date:'+ISO9000registrationdate+', Register:'+ISO9000registrar+',Quality System : TS16949 : Registration Date::'+registrationTS16949date+', Register:'+TS16949registrar+{
          method: 'POST',
        } )
        .then(res => {
           alert("Success"); 
              //console.log(res.response+"---data---"+res.data)
      
              //if(string.includes("Successfully")){
               // handleClickOpen3();
                
                 
             // }
          
        })
        .catch((error) => {
            console.log(error)
        })
        let path = `newPath`; 
        navigate('../thankyou');
      } 
      
       
    
    
        

    const [businesstype, setBusinesstype] = React.useState('');
    const [business, setBusiness] = React.useState('');

    const handleChange = (event) => {
        setBusinesstype(event.target.value);
      setBusiness(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Applicator/Contractor Opening Form</h2>
                          <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                
                  <div className="col-xl-12 col-lg-12">
                     
                    <form className="application-form" onSubmit={handleSubmitt}>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <TextField id="standard-basic" label="Company Name" variant="standard" value={name} onChange={(e) => setname(e.target.value)}  />
                  </div>
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Address" variant="standard" value={address} onChange={(e) => setAddress(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="City" variant="standard" value={city} onChange={(e) => setCity(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="State" variant="standard" value={state} onChange={(e) => setState(e.target.value)} />
                  </div>
                  
                  
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Zip" variant="standard" value={zip} onChange={(e) => setZip(e.target.value)} />
                  </div>

                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Telephone No." variant="standard" value={mobile} onChange={(e) => setMobileNumber(e.target.value)} />
                  </div>

                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Fax" variant="standard" value={fax} onChange={(e) => setFax(e.target.value)} />
                  </div>



                  </div>

                  <div className="row mt-40">
                       
                  <div className="col-xl-12 col-lg-12 mb-20">
                        <h5>Management</h5>
                    </div>
                    
                  <div className="col-xl-12 col-lg-12">
                        <p><b>President</b></p>
                    </div>


                  <div className="col-xl-3 col-lg-3">
                  <TextField id="standard-basic" label="Name" variant="standard" value={presidentname} onChange={(e) => setPresidentname(e.target.value)} />
                  </div>
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Phone Number" variant="standard" value={presidentnumber} onChange={(e) => setPresidentnumber(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Fax" variant="standard" value={presidentfax} onChange={(e) => setPresidentfax(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Experience(Yrs)" variant="standard" value={presidentexperience} onChange={(e) => setPresidentexperience(e.target.value)} />
                  </div>
                                

                  </div>


                  <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                                <p><b>Finance</b></p>
                            </div>


                        <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Name" variant="standard" value={financename} onChange={(e) => setFinancename(e.target.value)} />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Phone Number" variant="standard" value={financenumber} onChange={(e) => setFinancenumber(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Fax" variant="standard" value={financefax} onChange={(e) => setFinancefax(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Experience(Yrs)" variant="standard" value={financeexperience} onChange={(e) => setFinanceexperience(e.target.value)} />
                        </div>                                

                  </div>

                  
                  <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                                <p><b>Sales</b></p>
                            </div>


                        <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Name" variant="standard" value={salesname} onChange={(e) => setSalesname(e.target.value)} />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Phone Number" variant="standard" value={salesumber} onChange={(e) => setSalesnumber(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Fax" variant="standard" value={salesfax} onChange={(e) => setSalesfax(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Experience(Yrs)" variant="standard" value={salesexperience} onChange={(e) => setSalesexperience(e.target.value)} />
                        </div>                                

                  </div>

                  
                  <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                                <p><b>Purchasing</b></p>
                            </div>


                        <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Name" variant="standard" value={purchasingname} onChange={(e) => setPurchasingname(e.target.value)} />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Phone Number" variant="standard" value={purchasenumber} onChange={(e) => setPurchasenumber(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Fax" variant="standard" value={purchasingfax} onChange={(e) => setPurchasingfax(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Experience(Yrs)" variant="standard" value={purchasingexperience} onChange={(e) => setPurchasingexperience(e.target.value)} />
                        </div>                                

                  </div>

                  
                  <div className="row">
                    
                        <div className="col-xl-12 col-lg-12">
                                <p><b>Contact Person</b></p>
                            </div>


                        <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Name" variant="standard" value={contactpersonname} onChange={(e) => setContactpersonname(e.target.value)} />
                        </div>
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Phone Number" variant="standard" value={contactpersonnumber} onChange={(e) => setContactpersonnumber(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Fax" variant="standard" value={contactpersonfax} onChange={(e) => setContactpersonfax(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Experience(Yrs)" variant="standard" value={contactpersonexperience} onChange={(e) => setContactpersonexperience(e.target.value)} />
                        </div>                                

                  </div>

                  
                  <div className="row mt-40">
                       
                        <div className="col-xl-12 col-lg-12 mb-20">
                                <h5>Company Background</h5>
                            </div>
                            
                        <div className="col-xl-3 col-lg-3">
                        <TextField id="standard-basic" label="Owner" variant="standard" value={owner} onChange={(e) => setOwner(e.target.value)} />
                        </div>

                        
                        <div className="col-xl-3 col-lg-3">
                        <FormControl fullWidth className="select-box">
                                <InputLabel id="demo-simple-select-label">Select Business Type</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={businesstype}
                                label="Age"
                                onChange={handleChange} variant="standard" 
                                >
                                <MenuItem value={10}>Sole Proprietor </MenuItem>
                                <MenuItem value={20}>Partnership  </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-xl-3 col-lg-3">
                                <TextField id="standard-basic" label="Year Established" variant="standard" value={yearestablished} onChange={(e) => setYearestablished(e.target.value)} />
                        </div>
                        
                        <div className="col-xl-3 col-lg-3">
                        <FormControl fullWidth className="select-box">
                                <InputLabel id="demo-simple-select-label">Select Business Classification</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={business}
                                label="Age"
                                onChange={handleChange} variant="standard" 
                                >
                                <MenuItem value={10}>Large Business</MenuItem>
                                <MenuItem value={20}>Small Business  </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        
                        <div className="col-xl-12 col-lg-12">
                        <TextField id="standard-basic" label="Describe Types Of Processes, Capabilities, Materials Used"
                                multiline rows={3} variant="standard" required style={{width:'100%', marginBottom:'10px'}} value={remarks} onChange={(e) => setMessage(e.target.value)} />

                        </div>
                                

                  </div>

                    
                  <div className="row mt-40">
                       
                       <div className="col-xl-12 col-lg-12 mb-20">
                               <h5>Sales (Rupees)</h5>
                           </div>
                           
                       <div className="col-xl-3 col-lg-3">
                       <TextField id="standard-basic" label="Next Year Forecast" variant="standard" value={nextyearforcast} onChange={(e) => setNextyearforcast(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Current Year" variant="standard" value={currentyear} onChange={(e) => setCurrentyear(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Previous Year" variant="standard" value={previousyear} onChange={(e) => setPreviousyear(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Previous Three Years" variant="standard" value={previousthreeyear} onChange={(e) => setPreviousthreeyear(e.target.value)} />
                       </div>
                       

                 </div>

                 
                 <div className="row mt-40">
                       
                       <div className="col-xl-12 col-lg-12 mb-20">
                               <h5>Top 4 Customers</h5>
                           </div>
                           
                       <div className="col-xl-3 col-lg-3">
                       <TextField id="standard-basic" label="Customer 1" variant="standard" value={customer1} onChange={(e) => setCustomer1(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 2" variant="standard" value={customer2} onChange={(e) => setCustomer2(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 3" variant="standard" value={customer3} onChange={(e) => setCustomer3(e.target.value)} />
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 4" variant="standard" value={customer4} onChange={(e) => setCustomer4(e.target.value)} />
                       </div>
                       

                 </div>
                 
                 <div className="row mt-40">
                       
                       <div className="col-xl-12 col-lg-12 mb-20">
                               <h5>Top 4 Customers</h5>
                           </div>
                           
                       <div className="col-xl-3 col-lg-3">
                       <TextField id="standard-basic" label="Customer 1" variant="standard" value={topcustomer1} onChange={(e) => setTopcustomer1(e.target.value)}/>
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 2" variant="standard" value={topcustomer2} onChange={(e) => setTopcustomer2(e.target.value)}/>
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 3" variant="standard" value={topcustomer3} onChange={(e) => setTopcustomer3(e.target.value)}/>
                       </div>
                       <div className="col-xl-3 col-lg-3">
                               <TextField id="standard-basic" label="Customer 4" variant="standard" value={topcustomer4} onChange={(e) => setTopcustomer4(e.target.value)}/>
                       </div>
                       

                 </div>

                 
                 <div className="row mt-40">
                       
                       <div className="col-xl-12 col-lg-12 mb-20">
                               <h5>Quality System : (Please provide hard copies of certificates by post)</h5>
                           </div>
                           
                       <div className="col-xl-2 col-lg-2">
                        <p><b>ISO9000</b></p>
                       </div>
                       <div className="col-xl-5 col-lg-5">
                               <TextField id="standard-basic" label="Registration Date" variant="standard" value={ISO9000registrationdate} onChange={(e) => setISO9000registrationdate(e.target.value)}/>
                       </div>
                       <div className="col-xl-5 col-lg-5">
                               <TextField id="standard-basic" label="Registrar" variant="standard" value={ISO9000registrar} onChange={(e) => setISO9000registrar(e.target.value)}/>
                       </div>
                       

                 </div>
                 
                 <div className="row mt-40">
                       
                       <div className="col-xl-2 col-lg-4">
                        <p><b>TS16949</b></p>
                       </div>
                       <div className="col-xl-5 col-lg-5">
                               <TextField id="standard-basic" label="Registration Date" variant="standard" value={registrationTS16949date} onChange={(e) => setRegistrationTS16949date(e.target.value)}/>
                       </div>
                       <div className="col-xl-5 col-lg-5">
                               <TextField id="standard-basic" label="Registrar" variant="standard" value={TS16949registrar} onChange={(e) => setTS16949registrar(e.target.value)}/>
                       </div>
                       
                  
                       <div className="col-lg-12 mt-40">
                  <Button type="submit" variant="contained">Send Now</Button>

                  </div>

                 </div>
                  
                  </form>


                  </div>

               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Vendor;
