import React, {Component, useState, } from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate, withRouter  } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import FaxIcon from '@mui/icons-material/Fax';
import TollIcon from '@mui/icons-material/Toll';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))



function Contact() {
 
  
  const [name, setName] = useState("");
  const [emailID, setEmail] = useState("");
  const [mobile, setMobileNumber] = useState("");
  const [remarks, setMessage] = useState("");


  let navigate = useNavigate(); 


  
 let handleSubmitt = async (e) => {
  e.preventDefault();
 
  fetch
   ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks, {
    method: 'POST',
  } )
  .then(res => {
     alert("Success"); 
        //console.log(res.response+"---data---"+res.data)

        //if(string.includes("Successfully")){
         // handleClickOpen3();
          
           
       // }
    
  })
  .catch((error) => {
      console.log(error)
  })
  let path = `newPath`; 
  navigate('../thankyou');
} 



    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix" id="chairman-msg">
            <div className="container">
                
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Contact Details</h2>
                          <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.</p>
                        </div>
                  </div>
                </div>
            
            </div>
         </section>
         <section className="address">

<div className="container">
   

  
      
   <div className="row">
   <div className="col-lg-12 col-md-12 adblocks">
      
   <Divider />
   <h5 className="text-center mb-20">Kamdhenu Ventures Limited <br/><small style={{fontSize:'16px', fontWeight:'400'}}>Registered Office</small></h5>

   <div className="row mb-80">
      <Stack direction="row" spacing={2} className="mb-20 stacking">
            
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/6.png'} alt="Slide" className="addressimg" />
            <b>CIN</b>  L51909HR2019PLC089207
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/7.png'} alt="Slide" className="addressimg" />
            <b>Address</b>  2nd Floor, Tower-A, Building No. 9,  DLF Cyber City, Phase-III, Gurgaon - 122 002 (Haryana)
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/8.png'} alt="Slide" className="addressimg" />
            <b>Phone</b>  0124-4604500 (30 Lines)
            </Item>
               
      </Stack>
      
      <Stack direction="row" spacing={2} className="stacking">
          
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/9.png'} alt="Slide" className="addressimg" />
               <b>Email</b> cs@kamdhenupaints.com
               </Item>
               
               <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/10.png'} alt="Slide" className="addressimg" />
               <b>Website</b> www.kamdhenupaints.com
               </Item>
      </Stack>
      <divider></divider>
   </div>

    
   <Divider />
   <h5 className="text-center mb-20">Kamdhenu Colour and Coatings Limited <br/><small style={{fontSize:'16px', fontWeight:'400'}}>Registered Office</small></h5>

   <div className="row">
      <Stack direction="row" spacing={2} className="mb-20 stacking">
            
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/6.png'} alt="Slide" className="addressimg" />
            <b>CIN</b>  U36990HR2019PLC089197
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/7.png'} alt="Slide" className="addressimg" />
            <b>Regd Office</b>  2nd Floor, Building No. 9A,<br/> DLF Cyber City, Phase-III, Gurugram, Haryana - 122 002 (India)
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/8.png'} alt="Slide" className="addressimg" />
            <b>Phone</b>  0124 4604500
            </Item>
               
      </Stack>
      
      <Stack direction="row" spacing={2} className="mb-20 stacking">
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/11.png'} alt="Slide" className="addressimg" />
               <b>Toll Free Number</b> 1800 1800 545
               </Item>
               
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/12.png'} alt="Slide" className="addressimg" />
               <b>Fax</b> 0124-4218524
               </Item>
               
          
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/9.png'} alt="Slide" className="addressimg" />
               <b>Email</b> info@kamdhenupaints.com
               </Item>
      </Stack>

      
      <Stack direction="row" spacing={2} className="mb-20 stacking">
     
               
               <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/13.png'} alt="Slide" className="addressimg" />
               <b>Plant</b> E-538-539A, RIICO Industrial Area, Chopanki, Distt.-Alwar, Rajasthan-301 707
               </Item>

               <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/10.png'} alt="Slide" className="addressimg" />
               <b>Phone</b> 01493 666604
               </Item>
      </Stack>

     
   <Divider />
   </div>


  
      </div>
      
   </div>

      </div>
</section>

         <section className="contact ptb-100">
         <div className="container">
            
         <div className="row">
                     <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                     <div className="section__title-wrapper mb-40">
                              <h2 className="section__title font-36">Let's Talk</h2>
                              <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.

</p>
                              
                           </div>
                           <Card className="ctcard">
                              <div className="row d-flex justify-content-center">
                                

                                <div className="col-lg-4">
                                <img src={process.env.PUBLIC_URL + '/images/products1.jpg'} alt="Slide" className="ct-img" />
                                
                                </div>
                              <div className="col-lg-8">
                              <form onSubmit={handleSubmitt}>
                                  <div className="row g-4">
                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" required value={name} name="name" placeholder="Your Name: *" onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                    </div>

                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="email" name="emailID" placeholder="Your E-mail:"
                                    value={emailID}  onChange={(e) => setEmail(e.target.value)} required />
                                    </div></div>
                                    
                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" id="mobile" required name="mobile" placeholder="Phone Number:"
                                     value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} />
                                    </div>
                                    </div>

                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" name="fname" placeholder="Subject:"/>
                                    </div>
                                    </div>

                                    <div className="col-lg-12">
                                    <div className="form-inner">
                                    <textarea name="remarks" id="remarks" cols="30" rows="6" placeholder="Write A Question"
                                     required value={remarks}  onChange={(e) => setMessage(e.target.value)} ></textarea>
                                    </div>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                    <Button type="submit" variant="contained" className="formbtnn">Send Now</Button>

                                    </div>
                                    </div>
                                 </form>
                              </div>

                              
                              </div>
               </Card>  
                           
                     </div>
               </div>


               </div>   
</section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Contact;
