import React, { Component, useState, useEffect } from "react";
import './index.css';
import { BrowserRouter, Link, NavLink, useParams } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer'
import $ from 'jquery';

import Button from '@mui/material/Button';

function BlogDetails() {
    const { id } = useParams();
    const [blogData, setblogData] = useState([]);
    const filterPra = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": {}, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 3 }
    function fun_blogDataList() {
        fetch(
            "https://api.dialerp.com/api/api/getTemplateDataList",
            {

                method: "POST",
                headers: {
                    "Authorization-Token":
                        "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
                    Accept: "application/json, text/plain",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(filterPra),

            }
        ).then((result) => {
            result.json().then((resp) => {
                setblogData(resp);
            });
        });
    }
    useEffect(() => {
        fun_blogDataList();
    }, [0]);
    return (
        <>    <Header />
            <div className="contentblock blog bdetails" style={{ background: '#f8f8f8' }}>
                <div className="directorlist container">
                    <div className="row">
                        {blogData?.response?.records?.filter((blog) => blog[0].value === id)?.map((blog, i) => (
                            <React.Fragment key={i}>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <h5> {blog[3].value}</h5>
                                </div>
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                                    <img src={blog[5].value} alt="Slide" style={{ width: '100%', borderRadius: '5px', marginBottom: '15px' }} />
                                    <div dangerouslySetInnerHTML={{ __html: blog[4].value }} />
                                </div>

                            </React.Fragment>
                        ))}
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                            <div className="sidebloglist">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Other Blogs</h6>
                                    </div>
                                </div>
                                {blogData?.response?.records?.map((blog, i) => (
                                    <React.Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="features__icon-3 mb-30 p-relative">
                                                    <Link to="/blog-details">
                                                        <img src={blog[5].value} alt="Slide" style={{ width: '100%', borderRadius: '5px', minHeight: '63px' }} />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <Link to={`/blog-details/${blog[0].value}`}> <strong>{blog[3].value}</strong></Link>
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div><Footer /></div>
        </>

    );
}

export default BlogDetails;
