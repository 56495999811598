import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directorsunilkumar() {
   useEffect(() => {
      window.scrollTo(0, 0)

    }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Shri Sunil Kumar Agarwal
</h2>
                          <p>Chairman</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/sunil.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Shri Sunil Kumar Agarwal is one of the founding members of Kamdhenu Ventures Limited. He brings with him more than
35 years of experience in marketing which helped him build an efficient pan-India
network to help Kamdhenu reach new heights.</p>
<p>Shri Agarwal earned a Bachelor&#39;s degree (Chemical) from HBTI, Kanpur and has
accumulated a vast knowledge about running a successful company, including but
not limited to business strategy development &amp; implementation process
management, costing/ pricing analysis, project planning, quality control etc. He is the
brain behind the successful expansion in the regional steel market through franchise
agreements with competent companies.</p>
<p>The identification of Paints as an important product line happened under his
leadership and his guidance has been instrumental in the growth of the paints
business. Shri Sunil Agarwal has been the driving force of the company with his
keen understanding of market dynamics. He has successfully mobilised the country&#39;s
performing steel products manufacturing units to join hands with the Kamdhenu
Group hence ensuring a strong foothold in the steel sector.</p>
                    


                  </div>

             
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directorsunilkumar;
