import React, {Component, useEffect, useState } from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { render } from 'react-dom';
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import FaxIcon from '@mui/icons-material/Fax';
import TollIcon from '@mui/icons-material/Toll';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Carousel from 'react-bootstrap/Carousel';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

//import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from "react-responsive-carousel";
import {Helmet} from "react-helmet";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))



function Home() {

  
   const [name, setName] = useState("");
   const [emailID, setEmail] = useState("");
   const [mobile, setMobileNumber] = useState("");
   const [remarks, setMessage] = useState("");
 
 
   let navigate = useNavigate(); 
 

   
  let handleSubmitt = async (e) => {
   e.preventDefault();
  
   fetch
    ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks, {
     method: 'POST',
   } )
   .then(res => {
      alert("Success"); 
         //console.log(res.response+"---data---"+res.data)
 
         //if(string.includes("Successfully")){
          // handleClickOpen3();
           
            
        // }
     
   })
   .catch((error) => {
       console.log(error)
   })
   let path = `newPath`; 
   navigate('../thankyou');
 } 



   

  return (
   

    <>
    
   <Helmet>
   <title>Paints for Home| Wall & Roof Paints - Kamdhenu Paints</title>
   <meta name="description" content="Kamdhenu paints is the best home painting, wall and roof paints manufacturer in India. Explore a wide range of Home wall paint colors and waterproofing solutions." />
   </Helmet>

    <div><Header /></div>
      <div className="homecarousal">
       
        <Carousel>
      <Carousel.Item>
   
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner1.jpg'}
          alt="Kamdhenu Paints"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner2.jpg'}
          alt="Kamdhenu Paints"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner3.jpg'}
          alt="Kamdhenu Paints"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={process.env.PUBLIC_URL + '/images/banner4.jpg'}
          alt="Kamdhenu Paints"
        />

      </Carousel.Item>
    </Carousel>
      
      </div>
      <section className="features__area pt-125 pb-100 fix abtbl">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-5 col-xl-6 col-lg-6">
                     <div className="features__wrapper-3 features__wrapper-border pr-45">
                        <div className="section__title-wrapper mb-80 text-lg-end">
                           
                           <h2 className="section__title section__title-2">Providence of <br/> In-Depth Decor Solutions</h2>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-6 offset-xxl-1 col-xl-6 col-lg-6">
                     <div className="features__wrapper-3">
                        <p className="top-para">Being one of the leading paint companies in the country, 
                          we offer best-in-class Painting solutions so that you can possess an 
                          intricately carved décor and make your utopian dream-home a reality!</p>
                          </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 fitem-1 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-1.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                            View our colour range
                           </h3>
                           <p>Our product development research team strive to bring new product offering to our valued customers and vouch for perfection
                             while doing so. Select the best suited color to add a new definition to your home.</p>
                            </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 fitem-2 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                              Meet our experts
                           </h3>
                           <p>From making a selection of the appropriate hue to the attainment of a proficient finishing — our internal decoration 
                            experts are aptly qualified to assist you in these pondering tasks.</p>
                            </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                     <div className="features__item-3 fitem-3 text-center grey-bg mb-30">
                        <div className="features__icon-3 mb-30 p-relative">
                           <span>
                           <img src={process.env.PUBLIC_URL + '/images/icon/fea-3.png'} alt="Slide" />
                           </span>
                        </div>
                        <div className="features__content-3">
                           <h3 className="features__title features__title-3">
                             Paint budget calculator
                           </h3>
                           <p>Look out for the estimating considerations for your décor project with the 
                            predictions of amount of paint as well as the costing contemplations.</p>
                          </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>



         <section className="features__area fbg pt-130 pb-130 range">
            <div className="container">
               <div className="row">
                     <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                     <div className="section__title-wrapper mb-40">
                              <h2 className="section__title">Range of Products offered by Kamdhenu Paints</h2>
                              <p>Just have a quick glance through our extensively stretched range of emulations, designer finishes, enamels and distempers of varied textures and styles to fixate that fitting combination to heighten the allure of your home.

</p>
                           </div>
                           
                     </div>
               </div>
               <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                     <div className="features__wrapper">
                        <div className="section__title-wrapper">
                         
                           <div className="features__inner">
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-1.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Dual Paints Series</h3>
                                    </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Interior Emulsion Range</h3>
                                   </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-3.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Exterior
Emulsion Range</h3>
</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                     <div className="features__wrapper">
                        <div className="section__title-wrapper">
                         
                           <div className="features__inner">
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-1.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Designer Range</h3>
                                    </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Distemper Range</h3>
                                   </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-3.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Premium Enamel Range</h3>
</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>


                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                     <div className="features__wrapper">
                        <div className="section__title-wrapper">
                         
                           <div className="features__inner">
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-1.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Wood Finishes</h3>
                                    </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-2.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Waterproofing Products</h3>
                                   </div>
                              </div>
                              <div className="features__item d-sm-flex align-items-center pr-40">
                                 <div className="features__icon mr-30">
                                    <span>
                                    <img src={process.env.PUBLIC_URL + '/images/icon/fea-3.png'} alt="Slide" />
                                    </span>
                                 </div>
                                 <div className="features__content">
                                    <h3 className="features__title">Other Products</h3>
</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>




               </div>
            </div>
         </section>

         <section className="portfolio__area portfolio__bg portfolio__bg-2 ptb-100 description">
            <div className="container">
            
               <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                     <div className="portfolio__item-2 p-relative mb-30">
                        <div className="portfolio__thumb-2 w-img">
                        <img src={process.env.PUBLIC_URL + '/images/kamdhenu.png'} alt="Slide" />
                        </div>
                       
                     </div>
                  </div>
                  
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="section__title-wrapper-2 mb-80">
                              <h2 className="section__title" style={{marginTop:'50px'}}>Kamdhenu Paints</h2>
                        <h2 className="section__title section__title-2" style={{marginBottom:'30px'}}>Desh Ka Naya Rang</h2>
                        <p>We offer a wide range of colours to paint your house. Whatever may the surface/area, 
                           we have the best suited colouring solutions for you. Kamdhenu Paints is instrumental 
                           in providing an extensive range of patterns, textures, styles, and paints.
                            It branched out as a subsidiary of Kamdhenu Limited and is subscribed to the concept
                             of India's glorious colourful diversity. DESH KA NAYA RANG Kamdhenu Paints has
                              emerged as paint vertical of this booming group to help make India realise its
                               Colour Dreamz. Kamdhenu Paints is progressively moving on the path of 
                               accomplishing the mission and vision of rendering Kamdhenu as brand for all 
                               varieties of solutions to accomplish the infrastructural needs of this colourful 
                               nation.

</p>
                     </div>
                  </div>
                  
                  
                  
               </div>
            </div>
         </section>



         <section className="address">

<div className="container">
   

  
      
   <div className="row">
   <div className="col-lg-12 col-md-12 adblocks">
   <Divider />
      
   <h5 className="text-center mb-20">Kamdhenu Ventures Limited <br/><small style={{fontSize:'16px', fontWeight:'400'}}>Registered Office</small></h5>

   <div className="row mb-80">
      <Stack direction="row" spacing={2} className="mb-20 stacking">
            
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/6.png'} alt="Slide" className="addressimg" />
            <b>CIN</b>  L51909HR2019PLC089207
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/7.png'} alt="Slide" className="addressimg" />
            <b>Address</b>  2nd Floor, Tower-A, Building No. 9,  DLF Cyber City, Phase-III, Gurgaon - 122 002 (Haryana)
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/8.png'} alt="Slide" className="addressimg" />
            <b>Phone</b> <a href="tel:0124-4604500" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>0124-4604500</a>  (30 Lines)
            </Item>
               
      </Stack>
      
      <Stack direction="row" spacing={2} className="stacking">
          
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/9.png'} alt="Slide" className="addressimg" />
               <b>Email</b> <a href="mailto:cs@kamdhenupaints.com" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>cs@kamdhenupaints.com</a> 
               </Item>
               
               <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/10.png'} alt="Slide" className="addressimg" />
               <b>Website</b> <Link to="/" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}> www.kamdhenupaints.com</Link>
               </Item>
      </Stack>
      <divider></divider>
   </div>

   <Divider />
   <h5 className="text-center mb-20">Kamdhenu Colour and Coatings Limited <br/><small style={{fontSize:'16px', fontWeight:'400'}}>Registered Office</small></h5>

   <div className="row">
      <Stack direction="row" spacing={2} className="mb-20 stacking">
            
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/6.png'} alt="Slide" className="addressimg" />
            <b>CIN</b>  U36990HR2019PLC089197
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/7.png'} alt="Slide" className="addressimg" />
            <b>Regd Office</b>  2nd Floor, Building No. 9A,<br/> DLF Cyber City, Phase-III, Gurugram, Haryana - 122 002 (India)
            </Item>
               
            <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/8.png'} alt="Slide" className="addressimg" />
            <b>Phone</b>  <a href="tel:0124 4604500" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>0124 4604500</a>  
            </Item>
               
      </Stack>
      
      <Stack direction="row" spacing={2} className="mb-20 stacking">
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/11.png'} alt="Slide" className="addressimg" />
               <b>Toll Free Number</b> <a href="tel:1800 1800 545" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>1800 1800 545</a> 
               </Item>
               
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/12.png'} alt="Slide" className="addressimg" />
               <b>Fax</b> 0124-4218524
               </Item>
               
          
      <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/9.png'} alt="Slide" className="addressimg" />
               <b>Email</b> <a href="mailto:info@kamdhenupaints.com" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>info@kamdhenupaints.com</a> 
               </Item>
      </Stack>

      
      <Stack direction="row" spacing={2} className="mb-20 stacking">
     
               
               <Item>   <img src={process.env.PUBLIC_URL + '/images/icon/13.png'} alt="Slide" className="addressimg" />
               <b>Plant</b> E-538-539A, RIICO Industrial Area, Chopanki, Distt.-Alwar, Rajasthan-301 707
               </Item>

               <Item>     <img src={process.env.PUBLIC_URL + '/images/icon/8.png'} alt="Slide" className="addressimg" />
               <b>Phone</b>  <a href="tel:01493 666604" style={{color:'rgba(0, 0, 0, 0.6)', textDecoration:'none'}}>01493 666604</a> 
               </Item>
      </Stack>

   </div>


   <Divider />
  
      </div>
      
   </div>

      </div>
</section>

<section className="reviews">
   <div className="container">
      <div className="row">
      <h3 className="" style={{marginTop:'50px', textAlign:'center', marginBottom:'20px'}}>Customer's Reviews</h3>
      <Carousel>
      <Carousel.Item>
   
      <p>"The interior paints are excellent and reasonably priced. Without a doubt, I'll tell everyone about it. Guys, just go for it because it's hard to find a good offer with quality like this.
            "</p>
      </Carousel.Item>
      <Carousel.Item>
      <p>
      People at Kamdhenu Paints did a great job. Price-wise, they are superior than all other vendors; a quote I received from one of their rivals was 40% more expensive than Kamdhenu Paints'.

            </p>

      </Carousel.Item>
      <Carousel.Item>
      <p>It was a good experience with Kamdhenu Paints so far and they have been very helpful.

            </p>

      </Carousel.Item>
      <Carousel.Item>
      <p>In our overall experience with the services, we are pleased to say that we had a positive and satisfactory journey right from the initial stages. We extend our best wishes for continued success to the Kamdhenu Paints team.

            </p>

      </Carousel.Item>
      <Carousel.Item>
      <p>Extremely happy about overall experience from Kamdhenu Paints. I highly recommend Kamdhenu Paints to everyone.
 </p>

      </Carousel.Item>
     
    </Carousel>

   
      </div>
   </div>
</section>
<section className="contact mt-20">
<div className="container">
   
<div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
            <div className="section__title-wrapper mb-40">
                     <h2 className="section__title font-36">Quick Contact</h2>
                     <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.
</p>
                     
                  </div>
                  <Card className="ctcard">
                     <div className="row d-flex justify-content-center">
                       

                       <div className="col-lg-4">
                       <img src={process.env.PUBLIC_URL + '/images/products1.jpg'} alt="Slide" className="ct-img" />
                       
                       </div>
                     <div className="col-lg-8">
                     <form onSubmit={handleSubmitt}>
                                  <div className="row g-4">
                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" required value={name} name="name" placeholder="Your Name: *" onChange={(e) => setName(e.target.value)}/>
                                    </div>
                                    </div>

                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="email" name="emailID" placeholder="Your E-mail:"
                                    value={emailID}  onChange={(e) => setEmail(e.target.value)} required />
                                    </div></div>
                                    
                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" id="mobile" required name="mobile" placeholder="Phone Number:"
                                     value={mobile} onChange={(e) => setMobileNumber(e.target.value.replace(/[^0-9]/g, ""))} />
                                    </div>
                                    </div>

                                    <div className="col-lg-6">
                                    <div className="form-inner">
                                    <input type="text" name="fname" placeholder="Subject:"/>
                                    </div>
                                    </div>

                                    <div className="col-lg-12">
                                    <div className="form-inner">
                                    <textarea name="remarks" id="remarks" cols="30" rows="6" placeholder="Write A Question"
                                     required value={remarks}  onChange={(e) => setMessage(e.target.value)} ></textarea>
                                    </div>
                                    </div>

                                    <div className="col-lg-12 text-center">
                                    <Button type="submit" variant="contained" className="formbtnn">Send Now</Button>

                                    </div>
                                    </div>
                                 </form>
                     </div>

                     
                     </div>
      </Card>  
                  
            </div>
      </div>


      </div>   
</section>

<div><Footer /></div>


    </>
  );
}


export default Home;
