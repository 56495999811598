import React, {Component} from "react";
import '../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import MetaTags from 'react-meta-tags';

function Exteriorange () {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>  
     <MetaTags>
          <title>Exterior Wall Paints and Exterior Home Painting - Kamdhenu Paints</title>
          <meta name="description" content="Exterior Paint - Kamdhenu provides the best  Exterior Wall Paints and Exterior Home Painting Colors. Explore a wild range of exterior paints." />
          <meta name="keywords" content="exterior wall paint, exterior home painting, exterior paints for house, exterior colour for house, colour combination for outer wall" />
    </MetaTags>
            <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix pb-100" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/products/Designer-Finishes.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-7 col-lg-7">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10">Exterior Range </h2>
                         <p>Discover the perfect match for your home right here.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Weather-Supreme/3d-weather-supreme.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Weather Supreme </h5>  
                        <p>Weather Supreme is an elastomeric, super protective 100% Acrylic Latex Paint specially formulated for Exterior Walls. It comes with 7-year warranty which means that it has long lasting durability. It also has High SRI Valuer which gives better protection to the Exterior Walls.

</p>                  <Link to="/weather-supreme"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Weather-Classic/3d-weather-classic.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Weather Classic</h5>
                          
                            <p>Weather Classic Exterior Emulsion is a high performance, long lasting exterior paint that comes with 5 years warranty and it is specially formulated to withstand Extreme Tropical Conditions of High Rainfall, Humidity and Sun Light.
</p>                 <Link to="/weather-classic"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>


                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Weather-Classic-Advance/3d-weather-classic-Advance.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Weather Classic Advance</h5>
                          
                            <p>Weather Classic Advance Exterior is an Elastomeric, Super Protective 100% Acrylic Paint which comes with Advance features of Anti- Dirt Pickup and High SRI Value. This Emulsion is the perfect solution of preventing walls from Dirt, Dust and Extreme Weather Conditions.

</p>                 <Link to="/weather-classic-advance"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>




                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Weather-Classic-Max/High-Sri-Value.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Weather Classic Max</h5>
                          
                            <p>Weather Classic Max is Anti Dust Exterior Emulsion with HD Quality is High Performance Premium Quality Exterior Paint which is specially designed with Anti Dirt Feature.

Weather Classic-Max is a high-performance premium quality exterior paint with HD quality and excellent durability under extreme tropical conditions of heat, heavy rain fall and high humidity.


</p>              <Link to="/weather-classic-max"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>


                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                        <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Weather-Supreme-Advance-Shyne/weather-supreme-10-year-bucket.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Weather Supreme Advance Shyne</h5>
                          
                            <p>WEATHER SUPREME ADVANCE SHYNE has been specially developed to provide Long lasting durability to the walls, it comes with 12 years Warranty.

Weather Supreme in an elastomeric, super protective 100% acrylic latex paint. Specially formulated for exterior walls. It is water resistant and protects the exterior walls from dampness, algae and fungal caused by lashing rain. 
</p>                 <Link to="/kamo-supreme-advance-shyne"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>




                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                        <div className="paint-box">
                          
                            <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield/3d-kamoshield.png'} alt="Slide" style={{width: '200px'}} />
                        </div>
                        <div className="paintdes">
                        <h5>Kamo Shield</h5>
                         
                            <p>Kamoshield is Economical Exterior Emulsion which comes with 3 years warranty. It is very suitable for Dry & Humid climate.</p>  
                            <p>
Kamoshield is a wall coating paint for exterior walls. The acrylic co-polymer emulsion-based wall coating is best for application on exterior in dry or moderately humid areas.
</p>                <Link to="/kamoshield"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                        </div>
                    </div>
                  </div>

<div className="col-xl-4 col-lg-4">
  <div className="paint-block">
      <div className="paint-box">
        
      <img src={process.env.PUBLIC_URL + '/images/products/exterior-range/Kamo-Shield-Adavance/3d-weather-classic.png'} alt="Slide" style={{width: '200px'}} />
      </div>
      <div className="paintdes">
      <h5>Kamoshield Advance</h5>
        
          <p>Kamoshield Advance is Economical Exterior Emulsion which comes with High SRI Value and it has 3 years warranty.

Kamoshield Advance is an acrylic co-polymer emulsion based economical exterior wall coating, is best for application on exterior in dry or moderately humid areas. This coating has excellent addition to different types of surfaces like cement plasters, concrete, asbestos etc. 
</p>                <Link to="/kamo-shield-advance"><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
      </div>
  </div>
</div>


               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Exteriorange ;
