import React, { Component, useState, useEffect } from "react";
import '../index.css';
import { BrowserRouter, Link, NavLink, useParams } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function ProductDetails() {
  const { id } = useParams();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const filterPra =
  {
    "pgSize": 1000,
    "index": 1,
    "toEmailID": "icici.amitsingh@gmail.com",
    "tabErpID": 1482,
    "useAtlusSearch": true,
    "filters": `{'SubSubCategoryID':${id}}`,
    //"{'SubSubCategoryID':id}",
    "viewFormat": "columnname",
  }
  const [imgurl, setimgurl] = React.useState('');
  const [SubSubCategoryname, setSubSubCategoryname] = React.useState('');
  const [SubSubCategoryDescription, setSubSubCategoryDescription] = React.useState('');

  const fun_SubSubCategoryList = () => {
    fetch(
      "https://kamdhenu.azurewebsites.net/api/Dynamic/GetRecords",
      {

        method: "POST",
        headers: {
          "Authorization-Token": "kQW2JTvGgckCcC6j5nW0k/nKxLljH0p9EplJQnpr/ksFwPuIefHCwZB8JNE4d4QHaDaif/Ky7T8lgpc7BflM46RCez8w5ERiH3D3j6XtHEkycmM29WqzJqa/t4g/s2WL0ibxHdwG0pV4hTRMrZTUEfxLfv9nVMjLJNCQ54j0amMUo5tJSHIQ2jEsZSTe/IXAmeCFP3ZPryPxK7LSoAO4Q3vGt9EXSPT+sLMFYbnmBObBrK9qFRIjHRoLLcjY7UkJ",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(filterPra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        // setSubSubCategoryata(resp);
        setimgurl(resp?.response?.records[0]["Banner"]);
        setSubSubCategoryname(resp?.response?.records[0]["SubSubCategory"]);
        setSubSubCategoryDescription(resp?.response?.records[0]["Description"]);
        fun_ProductDataList();
      });
    });
  }
  const [ProductData, setProductData] = useState([]);
  const [btnstatusProductDetails, setbtnstatusProductDetails] = useState("Yes");
  const [btnstatusTechnicalDetails, setbtnstatusTechnicalDetails] = useState("Yes");
  const [ProductDetails, setProductDetails] = useState();
  const [TechnicalDetails, setTechnicalDetails] = useState();
  const fun_ProductDataList = () => {
    const filterPra =
    {
      "pgSize": 1000,
      "index": 1,
      "toEmailID": "icici.amitsingh@gmail.com",
      "tabErpID": 1478,
      "useAtlusSearch": true,
      "filters": `{'SubSubCategoryID':${id}}`,
      //"{'SubSubCategoryID':id}",
      "viewFormat": "columnname",
    }
    fetch(
      "https://kamdhenu.azurewebsites.net/api/Dynamic/GetRecords",
      {

        method: "POST",
        headers: {
          "Authorization-Token": "kQW2JTvGgckCcC6j5nW0k/nKxLljH0p9EplJQnpr/ksFwPuIefHCwZB8JNE4d4QHaDaif/Ky7T8lgpc7BflM46RCez8w5ERiH3D3j6XtHEkycmM29WqzJqa/t4g/s2WL0ibxHdwG0pV4hTRMrZTUEfxLfv9nVMjLJNCQ54j0amMUo5tJSHIQ2jEsZSTe/IXAmeCFP3ZPryPxK7LSoAO4Q3vGt9EXSPT+sLMFYbnmBObBrK9qFRIjHRoLLcjY7UkJ",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(filterPra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
      });
    });
  }
  useEffect(() => {
    fun_SubSubCategoryList();
  }, [id]);

  return (
    <>    <Header />
      <div className="contentblock pdt-0">

        <section className="fix" id="chairman-msg">
          <div className="banner-area">
            <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{ width: '100%' }} />
          </div>
          <div className="container">

            <div className="row mb-40">
              <div className="col-xl-12 col-lg-12">
                <div className="section__title-wrapper text-center">

                  <h2 className="section__title section__title-2 font-36 mb-10 prhead">{SubSubCategoryname}</h2>
                  <p>{SubSubCategoryDescription && <div dangerouslySetInnerHTML={{ __html: SubSubCategoryDescription }}></div>} </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="procuts-info ptb-100">
          <div className="info-overlay"></div>
          <div className="container pro-bg">
            <div className="row">
              {ProductData?.response?.records
                ?.sort((a, b) => a.SeqNo - b.SeqNo)
                ?.map((data, i) => {
                  if (btnstatusProductDetails === "Yes") {
                    if (data?.ProductDetails !== null && data?.ProductDetails !== undefined && data?.ProductDetails !== "undefined") {
                      setbtnstatusProductDetails("No");
                      setProductDetails(data?.ProductDetails);
                    }
                  }
                  if (btnstatusTechnicalDetails === "Yes") {
                    if (data?.TechnicalDetails !== null && data?.TechnicalDetails !== undefined && data?.TechnicalDetails !== "undefined") {
                      setbtnstatusTechnicalDetails("No");
                      setTechnicalDetails(data?.TechnicalDetails);
                    }
                  }

                  return (
                    <React.Fragment key={i}>
                      <div className="col-xl-3 col-lg-3">
                        <div className="paint-block">
                          <div className="paintdes ct2">
                            <img src={data?.ItemPic} alt="Slide" className="ct-img" />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              {/* {ProductData?.response?.records
                ?.sort((a, b) => a.SeqNo - b.SeqNo)
                ?.map((data, i) => {
                  if (data?.ProductDetails !== null
                    && data?.ProductDetails !== undefined
                    && data?.ProductDetails !== "undefined" && btnstatus === "Yes") {
                    setbtnstatus("No");
                    return ( */}
                    
                      <div className="col-xl-12 col-lg-12 text-center">
                      {btnstatusProductDetails === "No" &&<a className="product-btn dwnpdf" href={ProductDetails} download>Download PDF</a>}
                      {btnstatusTechnicalDetails === "No"&& <a className="product-btn dwnpdf" href={TechnicalDetails} download> Technical Specifications</a>}
                      </div>
                    {/* )
                  }
                })} */}
            </div>
          </div>
        </section>
      </div>
      <div><Footer /></div>
    </>

  );
}

export default ProductDetails;
