import React, { Component, useState, useEffect } from "react";
import './index.css';
import { BrowserRouter, Link, NavLink } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import GetAppIcon from '@mui/icons-material/GetApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageIcon from '@mui/icons-material/Image';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';


function NoticetoShareholders() {
  const [productdata, setProductData] = useState([]);
  const newArrayList = [];
  const productdata2 = { "NumberOfFieldsView": 50, "FromDt": "", "ToDt": "", "filters": { "Catagory": "Notice to Shareholders", }, "isDownload": false, "index": 1, "pgSize": 100, "templateID": 1 }
  function ProductData1() {
    fetch(
      "https://api.dialerp.com/api/api/getTemplateDataList",
      {

        method: "POST",
        headers: {
          "Authorization-Token":
            "2lgWh1N97o5O67IBXP9DiC63Td3QJNRhDcWqiynpap4t8f8a24beQ43PqLThA3bzeIHuLYHAZbeZM9YgS1PwARdKWBuewzyJK5NJfQYjZ0ehDmUktBxtIPdZXOeXIMqp8bVpVK/WQ/nd4+c0x1oDPA==",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(productdata2),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);

        console.log(resp, 'data')

      });
    });
  }
  useEffect(() => {
    ProductData1();

  }, []);

  productdata?.response?.records?.forEach(obj => {
    if (!newArrayList.some(o => o.value === obj[6].value)) {
      newArrayList.push({ ...obj[6] });
    }
  });


  console.log(newArrayList, 'rr');
  const playAudioInNewTab = (audioUrl) => {
    const newTab = window.open('', '_blank');
    newTab.document.write(`
          <html>
          <head>
            <title>Audio Player</title>
              <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                }
              </style>
            </head>
            <body>
              <audio controls autoplay>
                <source src="${audioUrl}" type="audio/mpeg">
                Your browser does not support the audio element.
              </audio>
            </body>
          </html>
          
        `);
  };
  return (
    <>    <Header />
      <div className="contentblock" style={{ padding: '70px 0px 400px 0px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="tableblock" style={{ border: '1px solid #d3d3d3' }}>
                <div className="accor">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>NORMS FOR FURNISHING PAN, KYC DETAILS AND NOMINATION</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="section__title-wrapper">
                        <h2 className="section__title font-36"> COMMON AND SIMPLIFIED NORMS FOR PROCESSING INVESTOR'S<br /> SERVICE REQUESTS BY RTAS AND NORMS FOR FURNISHING PAN, KYC DETAILS AND NOMINATION</h2>
                        <p style={{ marginTop: '16px' }}>As an on-going measure to enhance the ease of doing business for investors in the securities market, SEBI, has from time to time prescribed common and simplified norms for processing investor's service request by Registrars to an Issue and Share Transfer Agents (RTAs) and norms for furnishing PAN, KYC details and Nomination. In this direction SEBI vide its circular - SEBI/HO/MIRSD/MIRSD-PoD-1/P/CIR/2023/37 dated March 16, 2023, has mandated furnishing of PAN, Nomination, Contact Details, Bank Account details and Specimen Signature ("mandatory KYC") by the shareholders holding shares in physical form before 30th September, 2023</p>
                        <p>SEBI has also stipulated that if the shareholders holding shares in physical mode do not update the PAN, KYC and Nomination details or these details are not made available to the Company/their Registrar and Share Transfer Agents (RTA) on or after 1st October, 2023, then such folios shall be frozen by RTA as per the directive issued by SEBI.</p>

                        <p>These include requirements pertaining to:</p>
                        <ul className="listttt">
                          <li>Mandatory furnishing of PAN, KYC details and Nomination by holders of physical securities.
                          </li>
                          <li>Freezing of Folios without PAN, KYC details and Nomination.
                          </li>
                          <li>Attestation of documents.
                          </li>
                          <li>Mode for   providing   documents/details by   investors for   various   service requests.</li>
                          <li>Standardized, simplified and common norms for processing investor service requests.
                          </li>
                          <li>Forms for availing various Investor services.
                          </li>
                          <li>Indemnity.
                          </li>
                          <li>KYC details across all folios of the holder, maintained by the RTA.
                          </li>
                          <li>Timelines for registering of / updation of / change in PAN, KYC and nomination.
                          </li>
                          <li>Display of contact details of RTAs.
                          </li>
                          <li>All objections by RTA to be raised in one instance.
                          </li>
                          <li>Electronic interface for processing queries and complaints.
                          </li>
                        </ul>

                        <p>For detailed information, please refer to the attached SEBI circular -<Link to="https://www.sebi.gov.in/legal/circulars/mar-2023/common-and-simplified-norms-for-processing-investor-s-service-requests-by-rtas-and-norms-for-furnishing-pan-kyc-details-and-nomination_69105.html">SEBI/HO/MIRSD/MIRSD-PoD-1/P/CIR/2023/37</Link> dated March 16, 2023."</p>

                      </div>
                    </AccordionDetails>
                  </Accordion>

                  {newArrayList?.map((productitem122, i) => {
                    //console.log(productitem122.value, 'name')

                    return (



                      <Accordion>

                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{productitem122?.value}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <table cellPadding={0} cellSpacing={0} width={100}>
                              {/* {productdata?.response?.records?.slice().sort((a, b) => b[4].value.localeCompare(a[4].value)).map((productitem11, i) => { */}
                              {productdata?.response?.records?.slice().sort(function (a, b) { return new Date(b[4].value) - new Date(a[4].value) }).map((productitem11, i) => {
                                if (productitem122.value === productitem11[6].value) {
                                  var splitSentence = productitem11[7].value.split(" ");
                                  return (
                                    <tr key={productitem11.productdata}>
                                      <td>
                                        {/* {productitem11[7].value} */}
                                        {splitSentence.map((b, i) => {
                                          let flag = b.includes("https");
                                          if(flag===true){
                                          return (<><span><a href={b} style={{color:"blur"}} target="_blank"> {b}</a></span></> );}
                                          else{ return ( <> <span> {b}</span> </> ); }
                                        })}
                                      </td>
                                      <td className="download">
                                        {productitem11.map((productitem12, i) => {

                                          if (productitem12.name === "Pdf" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                <a href={productitem12.value} download><GetAppIcon /></a>

                                              </div>

                                            )
                                          }
                                          if (productitem12.name === "LargeSizeFileUrl" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                <a href={productitem12.value} target="_blank" download><GetAppIcon /></a>

                                              </div>

                                            )
                                          }

                                          if (productitem12.name === "Image" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                <a href={productitem12.value} download><ImageIcon /></a>

                                              </div>

                                            )
                                          }
                                          if (productitem12.name === "Audio" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                {/* <a href={productitem12.value} download><VolumeDownIcon/></a> */}
                                                <button onClick={() => playAudioInNewTab(productitem12.value)} style={{ color: '#ff0000', textAlign: 'right', float: 'right', border: '0px', padding: '0px', backgroundColor: '#ffffff00' }}><VolumeDownIcon /></button>
                                              </div>
                                            )
                                          }
                                          if (productitem12.name === "AudioUrl" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                <a href={productitem12.value}  target="_blank"><VolumeDownIcon /></a>
                                              </div>
                                            )
                                          }
                                          if (productitem12.name === "VideoUrl" && productitem12.value !== "") {
                                            return (
                                              <div key={productitem12.productdata}>
                                                <a href={productitem12.value} download><OndemandVideoIcon /></a>
                                              </div>
                                            )
                                          }
                                        })}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </table>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>)
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div><Footer /></div>
    </>

  );
}

export default NoticetoShareholders;
