import React, {Component} from "react";
import '../../index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function KamodualPremiumEmulsion() {
  
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock pdt-0">
      
    <section className="fix" id="chairman-msg">
          <div className="banner-area">
          <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{width: '100%'}} />
          </div>
            <div className="container">
              
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper text-center">
                           
                           <h2 className="section__title section__title-2 font-36 mb-10 prhead">Kamodual Premium Emulsion</h2>
                         <p>Kamodual Premium is a high-performance premium quality exterior & Interior paint specially designed. for excellent durability under extreme tropical conditions of heat, heavy rain fall and high humidity. Kamodual Premium is based on 100% pure acrylic resin system, which consider to be the as best protection against exterior & interior degradation. Its light fast pigments, special additives and anti- fungal/anti-algal agents impart excellent protection against alkali, UV degradation, fungal and algal growth for years. The product also enhances the richness in looks so as to add the beauty and elegance of the buildings with sweet fragrance.

</p>
                        </div>
                  </div>
                </div>
                
               
            </div>
         </section>
         <section className="procuts-info ptb-100">
         <div class="info-overlay"></div>
         <div className="container pro-bg">
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                       
                        <div className="paintdes ct2">     
                        <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Anti-Dirt-Pickup.png'} alt="Slide" className="ct-img" />
                        </div>
                    </div>
                   
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Anti-Fading.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                    
                        
                    </div>
                   
                  </div>


                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Anti-Microbiological.png'} alt="Slide" className="ct-img" />
                      
                        </div>


                     
                      
                    </div>
                   
                  </div>
                  
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">
                      
                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Frangance.png'} alt="Slide" className="ct-img" />
                      
                        </div>

                     
                      
                    </div>
                   
                  </div>
                  
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/High-Gloss-Icon.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/High-Opacity.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Long-Colour-Stability.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>

                         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/LOW-VOC.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
            
         
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Smooth-Finish.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
                     
                  <div className="col-xl-3 col-lg-3">
                    <div className="paint-block">

                    <div className="paintdes ct2">     
                    <img src={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Stain-Resistant.png'} alt="Slide" className="ct-img" />
                        </div>


                       
                    </div>
                   
                  </div>
            
            
            
                  
                  <div className="col-xl-12 col-lg-12 text-center">
                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Kamodual-Premium-Emulsion.pdf'} download>Download PDF</a>

                  <a className="product-btn dwnpdf" href={process.env.PUBLIC_URL + '/images/products/dual-paints-series/Kamodual/KamodualPremiumEmulsion/Kamodual-Premium-Emulsion-Technical-Literature.pdf'} download> Technical Specifications</a>
                 
                  </div>


               </div>

               </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default KamodualPremiumEmulsion;
