import React, { Component, useState, useEffect } from "react";
import '../index.css';
import { BrowserRouter, Link, NavLink, useParams } from 'react-router-dom';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import MetaTags from 'react-meta-tags';

function Product() {
  const { id } = useParams();
  const [age, setAge] = React.useState('');


  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterPra =
  {
    "pgSize": 1000,
    "index": 1,
    "toEmailID": "icici.amitsingh@gmail.com",
    "tabErpID": 1481,
    "useAtlusSearch": true,
    "filters": `{'SubCategoryID':${id}}`,
    //"{'SubSubCategoryID':id}",
    "viewFormat": "columnname",
  }
  const [imgurl, setimgurl] = React.useState('');
  const [SubSubCategoryname, setSubSubCategoryname] = React.useState('');
  const [SubSubCategoryDescription, setSubSubCategoryDescription] = React.useState('');

  const fun_SubSubCategoryList = () => {
    fetch(
      "https://kamdhenu.azurewebsites.net/api/Dynamic/GetRecords",
      {

        method: "POST",
        headers: {
          "Authorization-Token": "kQW2JTvGgckCcC6j5nW0k/nKxLljH0p9EplJQnpr/ksFwPuIefHCwZB8JNE4d4QHaDaif/Ky7T8lgpc7BflM46RCez8w5ERiH3D3j6XtHEkycmM29WqzJqa/t4g/s2WL0ibxHdwG0pV4hTRMrZTUEfxLfv9nVMjLJNCQ54j0amMUo5tJSHIQ2jEsZSTe/IXAmeCFP3ZPryPxK7LSoAO4Q3vGt9EXSPT+sLMFYbnmBObBrK9qFRIjHRoLLcjY7UkJ",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(filterPra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        debugger
        // setSubSubCategoryata(resp);
        setimgurl(resp?.response?.records[0]["Banner"]);
        setSubSubCategoryname(resp?.response?.records[0]["SubCategory"]);
        setSubSubCategoryDescription(resp?.response?.records[0]["Description"]);
        fun_ProductDataList();
      });
    });
  }
  const [ProductData, setProductData] = useState([]);
  const fun_ProductDataList = () => {
    const filterPra =
    {
      "pgSize": 1000,
      "index": 1,
      "toEmailID": "icici.amitsingh@gmail.com",
      "tabErpID": 1482,
      "useAtlusSearch": true,
      "filters": `{'SubCategoryID':${id}}`,
      //"{'SubSubCategoryID':id}",
      "viewFormat": "columnname",
    }
    fetch(
      "https://kamdhenu.azurewebsites.net/api/Dynamic/GetRecords",
      {

        method: "POST",
        headers: {
          "Authorization-Token": "kQW2JTvGgckCcC6j5nW0k/nKxLljH0p9EplJQnpr/ksFwPuIefHCwZB8JNE4d4QHaDaif/Ky7T8lgpc7BflM46RCez8w5ERiH3D3j6XtHEkycmM29WqzJqa/t4g/s2WL0ibxHdwG0pV4hTRMrZTUEfxLfv9nVMjLJNCQ54j0amMUo5tJSHIQ2jEsZSTe/IXAmeCFP3ZPryPxK7LSoAO4Q3vGt9EXSPT+sLMFYbnmBObBrK9qFRIjHRoLLcjY7UkJ",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(filterPra),

      }
    ).then((result) => {
      result.json().then((resp) => {
        setProductData(resp);
      });
    });
  }
  useEffect(() => {
    fun_SubSubCategoryList();
  }, [id]);
  return (
    <>

      <MetaTags>
        <title>Dual paints in india | Dual Paint Company - Kamdhenu Paints</title>
        <meta name="description" content="Kamdhenu Paints is the one of the best dual paints companies in India. Explore trhe wide rang of dual paints at Kamdhenu Paints. Contact Now!" />
        <meta name="keywords" content="Dual Paints, Dual paint companies, Dual paints in india" />
      </MetaTags>

      <Header />
      <div className="contentblock pdt-0">

        <section className="fix pb-100" id="chairman-msg">
      
          <div className="banner-area">
          {imgurl!==null&&imgurl!==""&&imgurl!==undefined?
            <img src={imgurl} alt="Slide" style={{ width: '100%' }} />
            : <img src={process.env.PUBLIC_URL + '/images/Dual-Paints-Series.jpg'} alt="Slide" style={{ width: '100%' }} />
        }
          </div>
          <div className="container">
            <div className="row mb-40">
              <div className="col-xl-7 col-lg-7">
                <div className="section__title-wrapper">

                {SubSubCategoryname  &&<h2 className="section__title section__title-2 font-36 mb-10">{SubSubCategoryname}</h2>}
                  {/* <p>Discover the perfect match for your home right here.</p> */}
                  <p>
                   {SubSubCategoryDescription &&<div dangerouslySetInnerHTML={{ __html: SubSubCategoryDescription }}></div>}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {ProductData?.response?.records
                ?.sort((a, b) => a.SeqNo - b.SeqNo)
                ?.map((data, i) => (
                  <div className="col-xl-4 col-lg-4">
                    <div className="paint-block">
                      <div className="paint-box">
                        <img src={data?.Pic} alt="Slide" style={{ width: '200px' }} />
                      </div>
                      <div className="paintdes">
                        <h5>{data?.SubSubCategory}</h5>
                        <p> <div dangerouslySetInnerHTML={{ __html: data?.Description }}></div></p>
                        <Link to={`/Product/Details/${data["SubSubCategoryID"]}`}><Button type="submit" variant="contained" className="product-btn">More Info</Button></Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>

      </div>

      <div><Footer /></div>
    </>

  );
}

export default Product;
