import React, {Component, useState} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Contractor() {
  
  
      const [depot, setDepot] = useState("");
      const [so, setSo] = useState("");
      const [name, setName] = useState("");
      const [applicatorcode, setApplicatorcode] = useState("");
      const [emailID, setEmail] = useState("");
      const [mobile, setMobileNumber] = useState("");
      const [dob, setDob] = useState("");
      const [date, setDate] = useState("");
      const [remarks, setMessage] = useState("");
      const [partnername, setPartnername] = useState("");
      const [officephone, setOfficephone] = useState("");
      const [residentialphone, setResidentialphone] = useState("");
      const [officeaddress, setOfficeaddress] = useState("");
      const [anniversary, setAnniversary] = useState("");
      const [tin, setTin] = useState("");
      const [banker, setBanker] = useState("");
      const [bankaddress, setBankaddress] = useState("");
      const [pan, setPan] = useState("");
      const [firmturnover, setFirmturnover] = useState("");
      const [project1, setProject1] = useState("");
      const [project2, setProject2] = useState("");
      const [project3, setProject3] = useState("");
      
    
      let navigate = useNavigate(); 
    
      
  
  let handleSubmitt = async (e) => {
      e.preventDefault();
     
      fetch
       ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks+', Depot:'+depot+', SO Code:'+so+', Date:'+date+', Partner Name:'+partnername+', Office phone no.:'+officephone+', Residential Phone No.:'+residentialphone+', Office Address:'+officeaddress+', Marriage Address:'+anniversary+', Tin No.:'+tin+', Banker Name:'+banker+', Bank Address:'+bankaddress+', Pan No.:'+pan+', Firm Turnover:'+firmturnover+', Project Undertaken 1:'+project1+', Project Undertaken 2:'+project2+', Project Undertaken 3:'+project3+{
        method: 'POST',
      } )
      .then(res => {
         alert("Success"); 
            //console.log(res.response+"---data---"+res.data)
    
            //if(string.includes("Successfully")){
             // handleClickOpen3();
              
               
           // }
        
      })
      .catch((error) => {
          console.log(error)
      })
      let path = `newPath`; 
      navigate('../thankyou');
    } 
    
     
  
      
    const [firm, setAge] = React.useState('');    
    const [exist, setExist] = React.useState(''); 
    const [turnover, setTurnover] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const handleChanges = (event) => {
      setExist(event.target.value);
    };
    const handleChangess = (event) => {
      setTurnover(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Applicator/Contractor Opening Form</h2>
                          <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/v-1.jpg'} alt="chairman" className="wd-100" />
                  </div>
                  <div className="col-xl-9 col-lg-9">
                     
                    <form className="application-form" onSubmit={handleSubmitt}>
               <div className="row">
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" label="Depot" variant="standard" value={depot} onChange={(e) => setDepot(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="S.O. Code" variant="standard" value={so} onChange={(e) => setSo(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Date" variant="standard" value={date} onChange={(e) => setDate(e.target.value)} />
                  </div>
                  
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Applicator's Name" variant="standard" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  
                  
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Applicator Code" variant="standard" value={applicatorcode} onChange={(e) => setApplicatorcode(e.target.value)}  />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                  <FormControl fullWidth className="select-box">
                        <InputLabel id="demo-simple-select-label">Select Type of Firm</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={firm}
                        label="Age"
                        onChange={handleChange} variant="standard" 
                        >
                        <MenuItem value={10}>Proprietor </MenuItem>
                        <MenuItem value={20}>Partnership  </MenuItem>
                        <MenuItem value={30}>Pvt.Ltd. </MenuItem>
                        <MenuItem value={40}>Public Ltd.</MenuItem>
                        </Select>
                    </FormControl>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Director's / Partners Name's" variant="standard" value={partnername} onChange={(e) => setPartnername(e.target.value)}  />
                  </div>

                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Office Phone No." variant="standard" value={officephone} onChange={(e) => setOfficephone(e.target.value)}  />
                  </div>

                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Residential Phone No." variant="standard" value={residentialphone} onChange={(e) => setResidentialphone(e.target.value)} />
                  </div>

                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" name="dob" required label="DOB" variant="standard" value={dob} onChange={(e) => setDob(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Official Address" variant="standard" value={officeaddress} onChange={(e) => setOfficeaddress(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Marrige Anniversary" variant="standard"value={anniversary} onChange={(e) => setAnniversary(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Mobile No." name="mobile" required variant="standard" value={mobile} onChange={(e) => setMobileNumber(e.target.value)}   />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" name="tin" required label="TIN No." variant="standard" value={tin} onChange={(e) => setTin(e.target.value)}  />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Banker's Name" variant="standard" value={banker} onChange={(e) => setBanker(e.target.value)}  />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Banker Address" variant="standard" value={bankaddress} onChange={(e) => setBankaddress(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Permanent Account No (PAN)" variant="standard" value={pan} onChange={(e) => setPan(e.target.value)}  />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                  <FormControl fullWidth className="select-box">
                        <InputLabel id="demo-simple-select-label">Select Party in Existence</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={exist}
                        label="Age"
                        onChange={handleChanges} variant="standard" 
                        >
                        <MenuItem value={130}>Over 20 Yrs</MenuItem>
                        <MenuItem value={140}>10-20 Yrs</MenuItem>
                        <MenuItem value={150}>5-10 Yrs</MenuItem>
                        <MenuItem value={160}>Below 5 Yrs</MenuItem>
                        </Select>
                    </FormControl>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                  <FormControl fullWidth className="select-box">
                        <InputLabel id="demo-simple-select-label">Select Annual Turnover- Asian (in Lakh)</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={turnover}
                        label="Age"
                        onChange={handleChangess} variant="standard" 
                        >
                        <MenuItem value={50}>Asian </MenuItem>
                        <MenuItem value={60}>GNP  </MenuItem>
                        <MenuItem value={70}>Berger</MenuItem>
                        <MenuItem value={80}>CI</MenuItem>
                        <MenuItem value={90}>Rajdoot</MenuItem>
                        <MenuItem value={100}>Shalimar</MenuItem>
                        <MenuItem value={120}>Any Other (Specify)</MenuItem>
                        </Select>
                    </FormControl>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Firm Turnover (Rs. Lakh)" variant="standard" value={firmturnover} onChange={(e) => setFirmturnover(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Projects Undertaken 1 (Amount)" variant="standard" value={project1} onChange={(e) => setProject1(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Projects Undertaken 2 (Amount)" variant="standard" value={project2} onChange={(e) => setProject2(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                        <TextField id="standard-basic" label="Undertaken 3 (Amount)" variant="standard" value={project3} onChange={(e) => setProject3(e.target.value)} />
                  </div>

                  <div className="col-lg-12 mt-40">
                  <Button type="submit" variant="contained">Send Now</Button>

                  </div>


                  </div>
                  </form>


                  </div>

               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Contractor;
