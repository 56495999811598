import React, {Component, useState} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';


function Distributor() {
  
      const [state, setState] = useState("");
      const [partner, setPartner] = useState("");
      const [name, setName] = useState("");
      const [tin, setTin] = useState("");
      const [emailID, setEmail] = useState("");
      const [mobile, setMobileNumber] = useState("");
      const [dob, setDob] = useState("");
      const [branch, setBranch] = useState("");
      const [remarks, setMessage] = useState("");
      const [comp1, setCompany1] = useState("");
      const [area1, setArea1] = useState("");
      const [comp2, setCompany2] = useState("");
      const [area2, setArea2] = useState("");
      const [comp3, setCompany3] = useState("");
      const [area3, setArea3] = useState("");
    
    
    

  let navigate = useNavigate(); 


  
  let handleSubmitt = async (e) => {
   e.preventDefault();
  
   fetch
    ("https://api.dialerp.com/api/WebApi/pushCRMDealMongo/40019A1?countryCode=USA&name="+name+"&emailID="+emailID+"&mobile="+mobile+"&remarks="+remarks+', state:'+state+', Tin number:'+tin+', Date of Birth:'+dob+', Branch:'+branch+', Name of Partner:'+partner+', Company 1:'+comp1+', Area 1:'+area1+', Company 2:'+comp2+', Area 1:'+area2+', Company 3:'+comp3+', Area 3:'+area3, {
     method: 'POST',
   } )
   .then(res => {
      alert("Success"); 
         //console.log(res.response+"---data---"+res.data)
 
         //if(string.includes("Successfully")){
          // handleClickOpen3();
           
            
        // }
     
   })
   .catch((error) => {
       console.log(error)
   })
   let path = `newPath`; 
   navigate('../thankyou');
 } 
 
  
      
    const [firm, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row mb-40">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36 mb-20">Application Form For CFA/CSA/Distributor
</h2>
                          <p>If you have any comments, concerns or suggestion for us, please use the form below. We would love to hear from you. We'll answer every application.</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/v-1.jpg'} alt="chairman" className="wd-100" />
                  </div>
                  <div className="col-xl-9 col-lg-9">
                     
                    <form className="application-form" onSubmit={handleSubmitt}>
                    <div className="row">
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" required placeholder="State" value={state} name="state" label="State" onChange={(e) => setState(e.target.value)} variant="standard" />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="partner" required value={partner} name="partner" label="Name of Proprietor  / Partner" onChange={(e) => setPartner(e.target.value)} variant="standard" />
                  </div>
                  
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="Firm Name" required value={name} name="name" label="Firm Name" variant="standard" onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                  <FormControl fullWidth className="select-box">
                        <InputLabel id="demo-simple-select-label">Select Type of Firm</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={firm}
                        label="Firm" variant="standard" required onChange={handleChange}>
                        <MenuItem value={10}>Proprietor </MenuItem>
                        <MenuItem value={20}>Partnership  </MenuItem>
                        <MenuItem value={30}>Pvt.Ltd. </MenuItem>
                        <MenuItem value={40}>Public Ltd.</MenuItem>
                        </Select>
                    </FormControl>
                  </div>
                  
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="Tin No." name="tin" required label="TIN No." variant="standard" value={tin} onChange={(e) => setTin(e.target.value)}  />
                  </div>
                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="mobile" name="mobile" required label="Mobile" variant="standard" value={mobile} onChange={(e) => setMobileNumber(e.target.value)}  />
                  </div>

                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="email" name="email" required label="Email" variant="standard" value={emailID} onChange={(e) => setEmail(e.target.value)}  />
                  </div>


                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="dob" name="dob" required label="DOB" variant="standard" value={dob} onChange={(e) => setDob(e.target.value)} />
                  </div>


                  <div className="col-xl-4 col-lg-4">
                  <TextField id="standard-basic" type="text" placeholder="Branch & Bank" required  name="branch" label="Branch & Bank" variant="standard" value={branch} onChange={(e) => setBranch(e.target.value)} />
                  </div>

                  <div className="col-xl-12 col-lg-12">
                  <TextField id="standard-basic" label="Companies dealing with as dealer "
                        multiline rows={3} variant="standard" value={remarks}  onChange={(e) => setMessage(e.target.value)} required style={{width:'100%', marginBottom:'10px'}} />


                                    </div>
                                
                  </div>

                  <div className="row mt-40">

                  <div className="col-xl-12 col-lg-12">
                        <h4>Companies Dealing with as Distributor & Area</h4>
                    </div>

                  <div className="col-xl-6 col-lg-6">
                    <TextField id="standard-basic" label="Company Name 1" variant="standard" value={comp1} name="comp1" onChange={(e) => setCompany1(e.target.value)} />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                        <TextField id="standard-basic" label="Area" variant="standard" name="area1" value={area1}  onChange={(e) => setArea1(e.target.value)} />
                  </div>
                  

                  <div className="col-xl-6 col-lg-6">
                    <TextField id="standard-basic" label="Company Name 2" variant="standard" value={comp2} name="comp2" onChange={(e) => setCompany2(e.target.value)} />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                        <TextField id="standard-basic" label="Area" variant="standard" value={area2}  onChange={(e) => setArea2(e.target.value)} name="area2"  />
                  </div>

                  

                  <div className="col-xl-6 col-lg-6">
                    <TextField id="standard-basic" label="Company Name 3" variant="standard" value={comp3} name="comp3" onChange={(e) => setCompany3(e.target.value)} />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                        <TextField id="standard-basic" label="Area" variant="standard" value={area3}  onChange={(e) => setArea3(e.target.value)} name="area3"  />
                  </div>

                  <div className="col-lg-12 mt-40">
                                    <Button type="submit" variant="contained">Send Now</Button>

                                    </div>

                  </div>

                  </form>


                  </div>

               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Distributor;
