import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import GetAppIcon from '@mui/icons-material/GetApp';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';



function DisclosuresunderRegulation46() {
  
  return (
    <>    <Header/>
  

    <div className="contentblock">
        <div className="container">
               <div className="row">
                   
                     <div className="col-lg-12 col-md-12">
                            <div className="section__title-wrapper">
                              <h2 className="section__title font-36">Disclosures under Regulation 46 of the LODR</h2>
                           </div>
                           <div className="tableblock" style={{border:'1px solid #d3d3d3'}}>
                            <table cellPadding={0} cellSpacing={0} width={100}>

                            <tr>
                                    <td>	Details of its Business</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/about#business-model">Click Here</a></td>
                                </tr>
                            <tr>
                                    <td>Terms and conditions of appointment of Independent Directors</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Composition of various Committees of Board of Directors</td>
                                    <td className="download"></td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/about#committies">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Code of Conduct of Board of directors and Senior Management Personnel</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Details of establishment of vigil mechanism/Whistle Blower policy</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Criteria of making payments to Non-executive Directors</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Policy on dealing with Related Party Transactions</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Policy for determining ‘material’ subsidiaries</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Details of Familiarization Programmes imparted to Independent Directors</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Number of Familiarisation programmes attended and number of hours spent by Independent Directors</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>The email address for grievance redressal and other relevant details</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-help-desk">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Contact information of the designated officials of the listed entity who are responsible for assisting and handling Investor Grievances</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-help-desk">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td colspan={2} style={{width:'100%'}}>Financial Information:

                                      <table cellPadding={0} cellSpacing={0} width="100">
                                        <tr>
                                          <td>	(i) Notice of meeting of the board of directors where Financial Results shall be discussed</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/stock-exchange-disclosures">Click Here</a></td>
                                        </tr>
                                        <tr>
                                          <td>(ii) Financial Results</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/financial-results">Click Here</a></td>
                                        </tr>
                                        <tr>
                                          <td>(iii) Annual Report</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/annual-results">Click Here</a></td>
                                        </tr>
                                      </table>
                                    </td>
                                  
                                </tr>
                                <tr>
                                    <td>	Shareholding Pattern</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/shareholding-pattern">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Details of agreements entered into with the media companies and/or their associates</td>
                                    <td className="download"></td>
                                </tr>
                                <tr>
                                    <td>Schedule of Analysts or Institutional Investors meet and presentations made by the listed entity to analysts or institutional investors</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/presentation">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Audio or video recordings and transcripts of post earnings/quarterly calls</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/presentation">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>New name and the old name of the listed entity for a continuous period of one year, from the date of the last name change</td>
                                    <td className="download"></td>
                                </tr>
                                <tr>
                                    <td>	Newspaper Advertisements under Regulation 47(1)</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/disclosures-under-regulation30">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>All Credit Ratings obtained</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/disclosures-under-regulation30">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Audited Financial Statements of Subsidiary Companies</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/subsidiary-financials">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Secretarial Compliance Report</td>
                                    <td className="download link"><a href="https://www.kamdhenupaints.com/stock-exchange-disclosures">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Disclosure of the policy for determination of materiality of events</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Disclosure of contact details of key managerial personnel</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-help-desk">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Disclosures under Regulation 30</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/disclosures-under-regulation30">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Statements of deviation(s) or variation(s) as specified in regulation 32 of these regulations</td>
                                    <td className="download link"><a href="https://www.kamdhenupaints.com/stock-exchange-disclosures">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>	Dividend Distribution policy</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/investor-zone">Click Here</a></td>
                                </tr>
                                <tr>
                                    <td>Annual Return</td>
                                    <td className="download link"><a href="https://kamdhenupaints.com/annual-return">Click Here</a></td>
                                </tr>
                            </table>
                        </div>
                     </div>     
                </div>                
        </div>
    </div>

<div><Footer /></div>
    </>

  );
}

export default DisclosuresunderRegulation46;
