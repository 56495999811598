import React, {Component, useState, useEffect} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink, useNavigate} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Directornishaljain() {
   useEffect(() => {
      window.scrollTo(0, 0)
  
    }, []);
  return (
    <>    <Header/>
    <div className="contentblock">
      
    <section className="fix pb-100" id="chairman-msg">
            <div className="container">
                
               <div className="row">
                  <div className="col-xl-12 col-lg-12">
                     <div className="section__title-wrapper">
                           
                           <h2 className="section__title section__title-2 font-36">Smt. Nishal Jain</h2>
                          <p>Independent Director</p>
                        </div>
                  </div>
                </div>
               <div className="row">
                  <div className="col-xl-3 col-lg-3">
                  <img src={process.env.PUBLIC_URL + '/images/directors/nishal.jpg'} alt="Slide" className="wd-100" style={{ border:'1px solid #999'}} />
                  </div>
                  <div className="col-xl-9 col-lg-9">
               
                           <p>Smt. Nishal Jain, PGDHR from Institute of Management and Technology, Kolkata is a seasoned
professional in the field of Human Resources Management. She has over 15 years of professional
experience in areas like Recruitment, Manpower Planning, Compensation Planning, Appraisals,
Employee Engagement &amp; related HRBP role. She had worked with various industries handling a team of
HR executives &amp; looked after the end to end recruitment, manpower planning, payroll, MIS &amp; other HR
operations.</p>
<p>She is currently running an HR Consultancy since last 4 years providing manpower consultancy and
supporting small businesses in managing their senior level manpower planning/ compensation planning/
performance analysis and basic compliance maintenance.</p>
                    


                  </div>
               </div>
               
            </div>
         </section>

    </div>

<div><Footer /></div>
    </>

  );
}

export default Directornishaljain;
