import React, {Component} from "react";
import './index.css';
import { BrowserRouter , Link, NavLink} from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { TextField } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';

function Terms() {


  return (
    <>    <Header/>
    <div className="contentblock privacycontent" style={{background:'#f8f8f8'}}>
    <Container>
    <h4>Terms and conditions</h4>   
		<p style={{marginBottom:'300px'}}>This page states the Terms and Conditions under which you (Visitor) may visit this website. Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit from this site. Kamdhenu Paints, any of its business divisions and / or its subsidiaries, associate companies or subsidiaries to subsidiaries (in India or abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-apprise yourself of the Terms and Conditions, because these are binding on all users of this website.

		</p>


 </Container>

    </div>
        <div><Footer /></div>
    </>

  );
}

export default Terms;
